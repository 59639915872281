<!-- Menu -->
<template>
  <ul class="menu">
    <MenuItem :key="index" v-for="(item, index) in Linkes">
      <span class="menu-title"  @click="golink(item)" v-if="!item.children">
        <router-link :to="{ path: item.path }">
          <van-icon :name="item.icon" /> {{ item.name }}
        </router-link>
      </span>

      <template v-else>
        <span class="menu-stitle" @click="handleToggleShow(item)">
          <span> <van-icon :name="item.icon" /> {{ item.name }} </span>
          <van-icon v-if="item.isopen" name="arrow-down" />
          <van-icon v-else name="arrow" />
        </span>
        <transition name="van-slide-left">
          <Menu :Linkes="item.children" v-if="item.isopen"></Menu>
        </transition>
      </template>
    </MenuItem>
  </ul>
</template>

<script>
import MenuItem from "./MenuItem";

export default {
  name: "Menu",
  props: {
    Linkes: Array,
  },
  components: { MenuItem },
  data() {
    return {
      toggleShow: false, // toggle 状态
    };
  },
  methods: {
    handleToggleShow(item) {
      item.isopen = !item.isopen;
    },
    golink(item){
      this.$store.dispatch('saveHeadTitle', item.name) 
      this.$store.dispatch('saveFootActive', item.name) 
    }
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.menu {
  .menu-link {
    position: relative;
    padding: 16px;
    padding-left: 0;
    margin-left: 32px;
    transition: all 0.5s;
    color: #F6EBE5;
    &::after{
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      width: 100%;
      height: 4px;
      box-shadow:  -1px -1px 1px #D01837, 1px 1px 1px #EAA8B2;
    }
    &:last-child::after{
       box-shadow: none;
    }
    &:hover {
      background: transparent;
      // background: #EAA8B2;
      transition: all 0.5s;
      .van-icon {
        color: #D01837;
      }
    }
    span {
      width: 100%;
      line-height: 30px;
      .van-icon {
        color: #EAA8B2;
        font-size: 24px;
        vertical-align: middle;
        padding-right: 6px;
      }
      .van-icon-arrow{
        font-size: 16px;
      }
      &.menu-stitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        a {
          color: #F6EBE5;
          
        }
      }
      &.menu-title {
        font-size: 14px;
        a {
          color: #F6EBE5;
        }
      }
    }
  }
}
</style>
