<template>
  <div>
    <nav-bar></nav-bar>
    <router-view :about="about"></router-view>
    <tab-bar></tab-bar>
  </div>
</template>

<script>
import NavBar from '@/components/Navbar'
import TabBar from '@/components/Tabbar'
import { getqueryOrgInfo } from "@/api/index";
export default {
  name: "nav-home",
  components: {
    NavBar,
    TabBar
  },
  data() {
    return {
      about:{},
      sign:'wx'
    }
  },
  mounted() {
    this.getqueryOrgInfos()
  },
  methods: {
    getqueryOrgInfos(){
      let sign = this.sign
      getqueryOrgInfo({sign}).then((res) => {
        if (res.code == 200) {
          this.about= res.data
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>

</style>