<template>
  <div>
    <div class="navbar bg-header">
      <!-- <van-icon name="search" size="2rem" @click="searshow = true" /> -->

      <van-image
        class="logo"
        width="40%"
        :src="require('@/assets/blogo.png')"
      />
      <van-icon name="wap-nav" size="2rem" @click="menushow = true" />
    </div>

    <van-popup
      v-model="menushow"
      position="left"
      get-container="html"
      class="overlay80vh"
    >
      <div class="nav-header">
        <a href="index.html">
          <img class="image-round" alt="" src="../assets/logo.png" />
          <!-- <span>武术</span>
        <span>wushu@example.com</span> -->
        </a>
      </div>

      <Menus :Linkes="Linkes"></Menus>
      <ul class="menu">
        <li class="menu-link" @click="logout">
          <span class="menu-title">
            <van-icon name="share-o" />
            退出登录
          </span>
        </li>
      </ul>
    </van-popup>

    <van-popup v-model="searshow" position="top"
      ><van-search
        v-model="searvalue"
        show-action
        shape="round"
        background="#f1f2f4 "
        @search="onSearch"
        placeholder="请输入搜索关键词"
      >
        <template #action>
          <van-button @click="onSearch" round> 搜索 </van-button>
        </template>
      </van-search>
    </van-popup>
  </div>
</template>

<script>
import Menus from "./Menu";
let homelins = {
  name: "首页",
  path: "/index",
  icon: "wap-home-o",
  isopen: false,
};
let loginOrsignup = {
  name: "登录/注册",
  path: "/login",
  icon: "upgrade",
  isopen: false,
  children: [
    {
      name: "登录",
      path: "/login",
      icon: "upgrade",
    },
    {
      name: "注册",
      path: "/signup",
      icon: "sign",
    },
    {
      name: "忘记密码",
      path: "/forgot-password",
      icon: "question-o",
    },
  ],
};

let my = {
  name: "我的",
  path: "/user",
  icon: "user-o",
};

let Linke = [
  {
    name: "新闻动态",
    path: "/news",
    icon: "volume-o",
    isopen: false,
    children: [
      {
        name: "政策法规",
        path: "/policy",
        icon: "award-o",
      },
      {
        name: "新闻动态",
        path: "/news",
        icon: "hot-o",
      },
      {
        name: "通知公告",
        path: "/information",
        icon: "volume-o",
      },
    ],
  },
  {
    name: "会员服务",
    path: "/members",
    icon: "vip-card-o",
  },
  {
    name: "段位查询",
    path: "/members",
    icon: "search",
  },
  {
    name: "赛事服务",
    path: "/event",
    icon: "smile-o",
    isopen: false,
    children: [
      {
        name: "赛事",
        path: "/event",
        icon: "smile-o",
      },
      {
        name: "活动",
        path: "/event/activity",
        icon: "flag-o",
      },
      {
        name: "培训",
        path: "/event/training",
        icon: "calendar-o",
      },
    ],
  },
  {
    name: "教学服务",
    path: "/teaching",
    icon: "video-o",
  },
  {
    name: "多媒体区",
    path: "/teaching",
    icon: "tv-o",
  },
  {
    name: "关于我们",
    path: "/about",
    icon: "friends-o",
    isopen: false,
    children: [
      {
        name: "协会简介",
        path: "/about",
        icon: "friends-o",
      },

      {
        name: "机构设置",
        path: "/institutions",
        icon: "cluster-o",
      },
      {
        name: "联系我们",
        path: "/contact-us",
        icon: "phone-o",
      },
    ],
  },
];



export default {
  name: "Header",
  components: {
    Menus,
  },
  data() {
    return {
      menushow: false,
      searshow: false,
      searvalue: "",
      activeNames: ["1"],
      active: "0",
      isUser: "",
      Linkes: [],
      oLinke: [
        homelins,
        my,
        ...Linke
      ],
      noLinke: [
        homelins,
        loginOrsignup,
        ...Linke
      ],
    };
  },

  created() {
    let data = sessionStorage.getItem("token");
    this.Linkes = data ? this.oLinke : this.noLinke;
  },
  methods: {
    onSearch() {},
    logout() {
      window.sessionStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped lang="scss">
.logo {
  display: inline-block;
  overflow: hidden;
}
.overlay80vh {
  width: 240px;
  height: 100%;
  background: #fbfffa;
}
.navbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: #fe051b;
  color: #fbfffa;
}

.bg-header {
  z-index: 10;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
}

.image-round {
  box-shadow: -10px 10px 30px #ddd;
  border: 4px solid #fbfffa;
  width: 30%;
  border-radius: 100%;
}

.nav-header {
  text-align: center;
  padding: 10px 0;
  a {
    color: #f4f11a;
  }
  span {
    display: block;
  }
}
</style>
