<!-- Menu -->
<template>
  <ul class="menu">
    <MenuItem :key="index" v-for="(item, index) in Linkes">
      <span class="menu-title" @click="golink(item)" v-if="!item.children">
        <router-link :to="{ path: item.path }">
          <van-icon :name="item.icon" /> {{ item.name }}
        </router-link>
      </span>

      <template v-else>
        <span class="menu-stitle" @click="handleToggleShow(item)">
          <span> <van-icon :name="item.icon" /> {{ item.name }} </span>
          <van-icon v-if="item.isopen" name="arrow-down" />
          <van-icon v-else name="arrow" />
        </span>
        <transition name="van-slide-left">
          <Menu :Linkes="item.children" v-if="item.isopen"></Menu>
        </transition>
      </template>
    </MenuItem>
    <!-- <li class="menu-link" @click="logout">
      <span class="menu-title">
        <van-icon name="share-o" />
        退出登录
      </span>
    </li> -->
  </ul>
</template>

<script>
import MenuItem from "./MenuItem";

export default {
  name: "Menu",
  props: {
    Linkes: Array,
  },
  components: { MenuItem },
  data() {
    return {
      toggleShow: false, // toggle 状态
    };
  },
  mounted() {},
  methods: {
    handleToggleShow(item) {
      item.isopen = !item.isopen;
    },
    golink(item) {
      this.$store.dispatch("saveHeadTitle", item.name);
      this.$store.dispatch("saveFootActive", item.name);
    },
    logout() {
      window.sessionStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.menu {
  .menu-link {
    padding: 6px;
    transition: all 0.5s;
    box-shadow: -6px 6px 16px #e5e7e6;
    border-radius: 16px;
    margin: 16px;
    &:hover {
      // background: #f4f11a;
      background: #f1f2f4;
      transition: all 0.5s;
    }
    span {
      width: 100%;
      vertical-align: top;
      line-height: 30px;

      .van-icon {
        color: #fe051b;
        font-size: 18px;
        vertical-align: bottom;
        border-radius: 16px;
        background: #fff;
        box-shadow: -6px 6px 16px #f7f8fa;
        padding: 6px;
      }
      &.menu-stitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        a {
          color: #1a1613;
        }
      }
      &.menu-title {
        font-size: 14px;
        a {
          color: #1a1613;
        }
      }
    }
  }
}
</style>
