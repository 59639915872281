<template>
  <div class="content">
    <div class="content-title">
      {{ detail.noticeTitle
      }}
      <span v-if="detail.noticeSource " class="sTime">来源：{{ detail.noticeSource }}</span>
      <span class="sTime">发表于：{{ detail.createTime }}</span>
      
    </div>
    <div class="content-text">
      <div v-html="detail.noticeContent"></div>
    </div>
  </div>
</template>
<script>
import { getNoticeDetail } from "@/api/index";
export default {
  name: "news-detail",
  props: {
    resData: Array,
    id: String,
  },
  data() {
    return {
      detail: [],
    };
  },
  mounted() {
    this.getNoticeDetails();
  },
  methods: {
    getNoticeDetails() {
      let id = this.id;
      getNoticeDetail({ id }).then((res) => {
        if (res.code == 200) {
          this.detail = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#app .content {
  padding-left: 16px;
  padding-right: 16px;
  .content-title {
    text-align: left;
  }
  .sTime {
    display: block;
    text-align: left;
    padding-top: 10px;
    color: #999;
    font-size: 14px;
  }
 
}
</style>