<template>
  <div class="content">
    <div class="user-head">
      <van-image
        round
        width="6rem"
        height="6rem"
        fit="cover"
        :src="loadUrl(user.avatar)"
      >
      
        <template v-slot:error>
          <van-icon name="contact" />
        </template>
      </van-image>

      <span class="name"> {{user.userName }}</span>
      <span class="tel">{{ user.phonenumber }}</span>
    </div>

    <section class="service">
      <ul>
        <li
          v-for="(serve, index) in service"
          :key="index"
          @click="onChangeTitle(serve)"
        >
          <router-link :to="serve.path">
            <van-icon :name="serve.icon" />
            <span>{{ serve.name }}</span>
          </router-link>
        </li>
      </ul>
    </section>

    <van-cell-group class="user-item">
      <van-cell
        title="我的信息"
        @click="onChangeTitle('我的信息')"
        value=""
        is-link
        to="myuser"
        icon="user-o"
      />
      <!-- <van-cell title="我的" value="" is-link to="myuser" icon="user-o" />
      <van-cell title="我的" value="" is-link to="myuser" icon="user-o" /> -->
      <van-cell title="退出" is-link icon="share-o" @click="logout" />
      <!-- <van-cell title="退出" value="内容" label="描述信息" /> -->
    </van-cell-group>
  </div>
</template>
<script>
import { getUserProfile } from "@/api/user";
export default {
  name: "my",
  data() {
    return {
      user: {},
      service: [
        {
          name: "会员服务",
          path: "/members",
          icon: "vip-card-o",
        },
         {
          name: "段位查询",
          path: "/members",
          icon: "search",
        },
        {
          name: "赛事服务",
          path: "/event",
          icon: "smile-o",
        },
        {
          name: "教学服务",
          path: "/teaching",
          icon: "video-o",
        },
        {
          name: "多媒体区",
          path: "/teaching",
          icon: "tv-o",
        },
      ],
    };
  },
  mounted() {
    this.getUserProfiles();
  },
  methods: {
    onChangeTitle(item) {
      let _title = item instanceof Object ? item.name : item;
      this.$store.dispatch("saveHeadTitle", _title);
    },
    getUserProfiles() {
      const self = this;
      self.$store.commit("showLoading");
      getUserProfile().then((res) => {
        if (res.code == 200) {
          self.$store.dispatch("saveUserInfo", res.data);
          self.user = res.data.userInfo;
          if (res.data.userType == 1){
            self.user = res.data.userInfo;
          } else {
            self.user = res.data.userInfo;
            self.user.avatar = res.data.orgInfo.img
            self.user.updateTime = res.data.orgInfo.updateTime
          }
      
          if (!self.user.updateTime) {
            self.$toast("请先完善用户基本信息！");
            self.$router.push("/myuser");
          } 
            
        } else {
          self.$toast("登录失败！");
        }
        self.$store.commit("hideLoading");
      });
    },
    logout() {
      window.sessionStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.user-head {
  text-align: center;
  padding: 16px 0;
  .van-image {
    border-radius: 50%;
    border: solid 8px #fff;
    box-shadow: -6px 6px 20px #eee;
  }
  /deep/ .van-icon {
    font-size: 5rem;
  }
  .name {
    padding: 10px 0;
    display: block;
    color: #1a1613;
    font-size: 20px;
  }
  .tel {
    display: block;
    color: #999;
    font-size: 14px;
  }
}
.service {
  padding: 16px;
  text-align: center;
  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    li {
      // margin: 10px;
      a,
      span {
        display: block;
        padding-top: 10px;
        color: #1a1613;
        font-size: 12px;
      }
    }
  }
  .van-icon {
    font-size: 28px;
    color: #fe051b;
    box-shadow: -10px 10px 20px #eaeaea;
    padding: 10px;
    border-radius: 100%;
    border: solid #fff 2px;
  }
}
.user-item {
  margin-top: 16px;
  &::after {
    border: 0;
  }
  .van-cell {
    margin: 10px 0;
    background: #fff;
    // border: solid 2px #fbfffa;
    // border-radius: 6px;
    // box-shadow: -6px 6px 16px #f7f8fa;

    border-radius: 22px;
    box-shadow: -4px 6px 16px #f1f2f4;

    &::after {
      border: 0;
    }
  }
}
</style>