<template>
  <div class="content">
    <div class="content-title">广东省武术协会</div>
    <div class="content-text">
       <ul class="institutions-list">
        <li>
          <span class="grade">联系人:</span>
          <span class="uName">{{ about.principal}}</span>
        </li>
        <li>
          <span class="grade">电话：</span>
          <span class="uName">{{ about.phone}}</span>
        </li>
        <li>
          <span class="grade">邮箱：</span>
          <span class="uName">{{ about.email}}</span>
        </li>
        <li>
          <span class="grade">地址：</span>
          <span class="uName">{{ about.area}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact-us",
  props:{
    about:Object
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.institutions-list{
  padding: 16px;
  li{
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    .grade{
      display: inline-block;
      // flex-shrink: 0;
      // width: 80px;
      padding-right: 16px;
    }
     .uName{
      display: inline-block;
      flex: 1;
      color: #999;
    }
  }
}
</style>