<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view />
    </transition>
    <Loading v-if="LOADING" />
    
  </div>
</template>
<script>
import Loading from "@/components/Loading";
import { mapState } from "vuex";
export default {
  name: "APP",
  components: {
    Loading,
  },
  data() {
    return {
      transitionName: "fadeInDown",
    };
  },
  computed: {
    ...mapState(["LOADING"]),
  },
  watch: {
    //使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "fadeInDown";
      } else {
        this.transitionName = "fadeInDown";
      }
    },
  },
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", "");
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1a1613;
  // background: #fbfffa;
  .content {
    padding: 60px 16px 80px;
    .content-title {
      text-align: center;
      color: #1a1613;
      font-size: 18px;
    }
    .content-text {
      line-height: 28px;
      color: #1a1613;
      font-size: 14px;
      p {
        text-indent: 2em;
      }
    }
  }
}
</style>
