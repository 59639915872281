<template>
  <div class="login">
    <div class="container">
      <div class="login-top">
        <img class="image-round" alt="" src="../../assets/logo.png" />
        <p>找回登录密码！</p>
      </div>
      <van-form @submit="onSubmit" class="base-form">
        <van-field
          v-model="username"
          name="用户密码"
          placeholder="请输入用户登录密码"
          label="用户密码"
          right-icon="closed-eye"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <van-field
          v-model="username"
          name="确认密码"
          placeholder="请再次输入密码"
          label="确认密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
        <van-field
          v-model="sms"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        >
          <template #button>
            <van-button size="small" class="btn-send">发送验证码</van-button>
          </template>
        </van-field>

        <van-button round block class="btn-true" native-type="submit"
          >修改</van-button
        >
      </van-form>
      <div class="item-bar">
        <span class="span-link"
          >已经有账号？<router-link to="/login" class="Black"
            >登录</router-link
          ></span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "forgot-password",
  data() {
    return {
      username: "",
      password: "",
      sms: "",
      userId: "",
      checkbox: false,
    };
  },
  methods: {
    onSubmit() {},
  },
};
</script>
<style lang="scss" scoped>
.login {
  height: 100vh;
  background: linear-gradient(to bottom, #fbfffa, #fff);
  .container {
    .login-top {
      text-align: center;
      padding: 10px;
      .image-round {
        box-shadow: -20px 20px 50px #ececec;
        border: 6px solid #fbfffa;
        border-radius: 100%;
      }
      img {
        width: 30%;
      }
    }
  }
}
.shadow {
  box-shadow: -10px 10px 20px #ececec, -10px 10px 20px #f1f2f4;
}
.base-form {
  .van-cell {
    margin: 0px 3% 16px;
    width: 94%;
    padding: 10px 20px;
    background: #fbfffa;
    border: solid 2px #fff;
    border-radius: 32px;
    box-shadow: -6px 6px 16px #eff0f5;
    &::after {
      border-width: 0;
    }
  }
}
.item-bar {
  position: relative;
  display: block;
  padding: 10px 16px;
  color: #999;
  font-size: 12px;
  .to-link {
    text-align: left;
    text-indent: 9em;
    margin-top: -20px;
    display: block;
  }
  .span-link {
    display: block;
    text-align: center;
  }
}
.btn-true {
  margin: 0px 3% 16px;
  width: 94%;
  background: #fe051b;
  border: solid #fe051b 4px;
  color: #fff;
  box-shadow: -6px 6px 20px #eee;
}
.btn-send {
  background: #fff;
  border: solid #f1f2f4 1px;
  color: #444;
  border-radius: 16px;
  box-shadow: -6px 6px 16px #f1f2f4;
}
.Red {
  color: #fe051b;
}
.Yellow {
  color: #f4f11a;
}
.Black {
  color: #1a1613;
}
.White {
  color: #fbfffa;
}
</style>