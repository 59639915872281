<template>
  <div class="login">
    <div class="container">
      <div class="login-top">
        <router-link class="forgot-password" to="/index"
          ><img class="image-round" alt="" src="../../assets/logo.png"
        /></router-link>
        <p>欢迎回来！</p>
      </div>
      <van-form @submit="onSubmit" class="base-form">
        <van-field
          v-model="username"
          name="用户名"
          placeholder="请输入用户名"
          label="用户名"
          maxlength="11"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="password"
          type="password"
          name="密码"
          label="密码"
          placeholder="请输入密码"
          right-icon="closed-eye"
          autocomplete
          :rules="[{ required: true, message: '请填写密码' }]"
          @click-right-icon="showPassword"
          maxlength="18"
        />
        <!-- <van-field
          v-model="sms"
          center
          clearable
          label="验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        >
          <template #button>
            <van-button size="small" type="danger" plain>发送验证码</van-button>
          </template>
        </van-field> -->

        <router-link class="forgot-password" to="/forgot-password"
          >忘记密码？</router-link
        >
        <div style="margin: 16px">
          <van-button
            round
            block
            class="btn-true"
            :disabled="checked"
            native-type="submit"
            >登 录</van-button
          >
        </div>
        <!-- <div class="item-bar">
          <van-checkbox v-model="checked" checked-color="#fe051b"
            >我已阅读并同意</van-checkbox
          ><span class="to-link">《相关服务条款和隐私政策》</span>
        </div> -->
        <div class="item-bar">
          <span class="span-link"
            >还没有账号？<router-link to="/signup" class="Black"
              >注册</router-link
            ></span
          >
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/user";
import { mapActions } from "vuex";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      sms: "",
      userId: "",
      checked: false,
    };
  },
  mounted() {
    let token = window.sessionStorage.getItem("token");
    if (token) {
      this.$router.push("/user");
    }
  },
  methods: {
    onSubmit() {
      var self = this;
      //这里写登录的操作
      let param = {
        loginType: 1,
        sign: "mobile",
        phone: self.username,
        password: self.password,
      };
      self.$store.commit("showLoading");
      login(param).then((res) => {
        if (res.token) {
          // localStorage.token = res.token;
          //  window.sessionStorage.removeItem("token")
          window.sessionStorage.setItem("token", res.token);
     
          // this.resetSetItem('changeData', res.token);
          if (self.$route.query.redirect) {
            //如果存在参数
            let redirect = self.$route.query.redirect;
            self.$router.push(redirect); //则跳转至进入登录页前的路由
            self.$store.dispatch("saveHeadTitle","我的");
            self.$store.dispatch("saveFootActive", "我的");
          } else {
            self.$router.push("/user");
            self.$store.dispatch("saveHeadTitle","我的");
            self.$store.dispatch("saveFootActive", "我的");
          }
        } else {
          this.$toast(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },
    showPassword(el) {
      let _input = el.target.parentNode.previousElementSibling;
      let _type = _input.type;
      if (_type == "password") {
        _input.setAttribute("type", "text");
      }
      if (_type == "text") {
        _input.setAttribute("type", "password");
      }
      // _type = _input.type == "password" ? "text" : "password"
    },

    logins() {
      let { username, password, sms } = this;
      this.axios
        .post("/user/login", {
          username,
          password,
          sms,
        })
        .then((res) => {
          this.$cookie.set("userId", res.id, { expires: "1M" });
          // this.$store.dispatch('saveUserName', this.username) // res.username
          this.saveUserName(this.username);
          this.$router.push("/index");
        });
    },
    ...mapActions(["saveUserName"]),
  },
};
</script>
<style lang="scss" scoped>
.login {
  height: 100vh;
  background: linear-gradient(to bottom, #fff, #fff);
  .container {
    .login-top {
      text-align: center;
      padding: 10px;
      .image-round {
        box-shadow: -10px 20px 50px #b8b8c4;
        border: 6px solid #fbfffa;
        border-radius: 100%;
      }
      img {
        width: 30%;
      }
    }
  }
}

.base-form {
  .van-cell {
    margin: 16px 4%;
    width: 92%;
    padding: 16px 20px;
    background: #fff;

    border-radius: 32px;
    box-shadow: -6px 6px 10px #b8b8c4;
    &::after {
      border-width: 0;
    }
  }
  .item-bar {
    position: relative;
    display: block;
    padding: 10px 16px;
    color: #999;
    font-size: 12px;
    .to-link {
      text-align: left;
      text-indent: 9em;
      margin-top: -20px;
      display: block;
    }
    .span-link {
      display: block;
      text-align: center;
    }
  }
  .forgot-password {
    display: block;
    padding: 10px 16px;
    color: #1a1613;
    font-size: 14px;
    text-align: right;
  }
}
</style>