<template>
  <div>
    <nav-header-blue />
    <div class="index-blue">
      <van-search
        v-model="searvalue"
        class="search-bar"
        @search="onSearch"
        placeholder="请输入搜索关键词"
      >
        <!--  show-action <template #action>
          <van-button class="btn-sear" @click="onSearch" round> 搜 </van-button>
        </template> -->
      </van-search>

      <van-swipe class="banner" :autoplay="5000" :loop="false">
        <van-swipe-item v-for="(ban, index) in banner" :key="index">
          <van-image fit="cover" lazy-load :src="loadUrl(ban.ckImg)" />
          <span class="ban-title">{{ ban.title}}</span>
        </van-swipe-item>
      </van-swipe>

      <section class="service">
        <ul>
          <li v-for="(serve, index) in service" :key="index">
            <router-link :to="serve.path">
              <van-icon :name="serve.icon" />
              <span>{{ serve.name }}</span>
            </router-link>
          </li>
        </ul>
      </section>

      <section class="news">
        <van-cell
          title="政策法规"
          value="更多"
          is-link
          to="news"
          icon="award-o"
        />
        <ul class="news-list">
          <li v-for="(nItem, index) in news.slice(0, 3)" :key="index">
            <span class="news-name">{{ nItem.noticeTitle }}</span>
            <span class="news-time">{{ sundoDate(nItem.createTime) }}</span>
          </li>
        </ul>
      </section>

      <section class="news">
        <van-cell
          title="新闻动态"
          value="更多"
          is-link
          to="news"
          icon="hot-o"
        />
        <ul class="news-list">
          <li v-for="(nItem, index) in news.slice(0, 3)" :key="index">
            <span class="news-name">{{ nItem.noticeTitle }}</span>
            <span class="news-time">{{ sundoDate(nItem.createTime) }}</span>
          </li>
        </ul>
      </section>

      <section class="news">
        <van-cell
          title="通知公告"
          value="更多"
          is-link
          to="news"
          icon="volume-o"
        />
        <ul class="news-list">
          <li v-for="(nItem, index) in news.slice(0, 3)" :key="index">
            <span class="news-name">{{ nItem.noticeTitle }}</span>
            <span class="news-time">{{ sundoDate(nItem.createTime) }}</span>
          </li>
        </ul>
      </section>

      <section class="news">
        <van-cell
          title="最新赛事"
          value="更多"
          icon="smile-o"
          is-link
          to="event"
        />
        <div class="event-box">
          <ul class="event">
            <li v-for="(event, index) in events.slice(0, 3)" :key="index">
              <van-image fit="cover" lazy-load :src="loadUrl(event.imgUrl)" />
              <div class="event-card">
                <h4>{{ event.competitionName }}</h4>
                <span class="time">
                  <van-icon name="underway-o" /> 申请：{{ sundoDate(event.competitionApplyBeginDate) }} 至  {{ sundoDate(event.competitionApplyEndDate) }}</span
                >
                 <span class="time">
                  <van-icon name="underway" />  比赛：{{ sundoDate(event.competitionMatchBeginDate) }} 至 {{ sundoDate(event.competitionMatchEndDate) }}</span
                >
                 <span class="site">
                  <van-icon name="map-marked" /> {{ event.province }}{{ event.city }}{{ event.address }}</span
                >
                <span class="gold">
                  <van-icon name="gold-coin" /> {{ event.applyPay }} 元</span
                >
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section class="Copyright c1">
        Copyright © 广东省武术协会 All rights reserved <br />技术支持
      </section>
    </div>
  </div>
</template>

<script>
import { getHome } from "@/api/index";
import NavHeaderBlue from "@/components/NavHeaderBlue";
export default {
  name: "index",
  components: {
    NavHeaderBlue,
  },
  data() {
    return {
      searvalue: "",
      banner: [],
      service: [
        {
          name: "会员服务",
          path: "/members",
          icon: "vip-card",
        },
        {
          name: "赛事服务",
          path: "/event",
          icon: "smile",
        },
        {
          name: "教学服务",
          path: "/teaching",
          icon: "video",
        },
        {
          name: "多媒体区",
          path: "/teaching",
          icon: "live",
        },
      ],
      news: [],
      events: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getHome().then((res) => {
        if (res.code == 200) {
          this.banner = res.data.bannerList;
          this.news = res.data.noticeList;
          this.events = res.data.competitionList;
        }
        // console.log(res);
      });
    },
    goToCart() {
      this.$router.push("/cart");
    },
    onSearch() {},
  },
};
</script>

<style lang="scss" scoped>
.index-blue {
  padding-top: 50px;
  background: #2c384e;
  .search-bar {
    padding: 16px;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background: #1d8cff;
    .van-search__content {
      background: #fff;
      padding: 6px;
      .van-field__left-icon .van-icon {
        font-size: 24px;
        color: #5c6a87;
      }
    }
    .btn-sear {
      box-shadow: none;
      color: #5c6a87;
      font-size: 24px;
      margin-top: 8px;
      padding: 0 8px;
    }
  }
  .banner {
    padding-top: 0;
    .van-swipe-item {
      .van-image {
        margin: 0;
        border-radius: 0;
      }
    }
  }
  .service {
    ul {
      li {
        a,
        span {
          display: block;
          padding-top: 10px;
          color: #ced6e1;
          font-size: 12px;
        }
      }
    }
    .van-icon {
      color: #fff;
      box-shadow: -3px 3px 6px #0c2648, -1px 1px 4px #142f4e;
      border-radius: 100%;
      background-color: #1483f6;
      border-color: transparent;
    }
  }
  .news {
    .van-cell__title {
      font-size: 16px;
      color: #1d8cff;
    }
    .van-cell {
      // background: #29354b;
      background: transparent;
    }
    .van-icon {
      color: #1d8cff;
    }
    .event-box .van-icon {
      font-size: 16px;
      vertical-align: middle;
      padding-right: 6px;
    }
    .event-box .time .van-icon{
        color: #576687;
    }
    .event-box .site .van-icon{
      color: #576687;
    }
    .event-box .gold,
    .event-box .gold .van-icon{
      color: #1d8cff;
    }
  }
  .news-list {
    padding-right: 0;
    background: #303d50;
    li {
      padding: 16px;
      padding-left: 0;
      margin: 0;
      border-radius: 0;
      box-shadow: none;
      color: #ced6e1;
      border: 0;
      border-bottom: solid #2b3648 2px;
      .news-time {
        color: #606b7f;
      }
      &:last-child {
        border-bottom-width: 0;
      }
    }
  }
}
</style>