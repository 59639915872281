import request from '@/util/request'
// 武协首页展示
export const getHome = params => {
    return request({
        url: '/app/orgHome/getOrgHome',
        method: 'get',
        params
    })
}

/**
 * 关于我们：
 *  协会简介， 机构设置， 联系我们
 */
export const getqueryOrgInfo = params => {
    return request({
        url: '/app/orgHome/queryOrgInfo',
        method: 'get',
        params
    })
}

/**
 * 查新闻列表 
 * 参数：noticeClass
 * 1通知公告，2政策法规，3新闻
 */
export const getNoticeList = data => {
    return request({
        url: '/app/notice/getNoticeList',
        method: 'post',
        data
    })
}

/**
 * 新闻详情 { id}
 * id
 */
export const getNoticeDetail = params => {
    return request({
        url: '/app/notice/getNoticeDetail',
        method: 'get',
        params
    })
}
