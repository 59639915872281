<template>
  <van-tabbar
    class="tab-bar"
    v-model="active"
    active-color="#fe051b"
    inactive-color="#1a1613"
    @change="onChange"
  >
    <van-tabbar-item replace to="/index" name="首页" icon="home-o"
      >首页</van-tabbar-item
    >
    <template v-if="footclass == '关于我们'">
      <van-tabbar-item replace to="/about" name="协会简介" icon="friends-o"
        >协会简介</van-tabbar-item
      >
      <van-tabbar-item
        replace
        to="/institutions"
        name="机构设置"
        icon="cluster-o"
        >机构设置</van-tabbar-item
      >
      <van-tabbar-item replace to="/contact-us" name="联系我们" icon="phone-o"
        >联系我们</van-tabbar-item
      >
    </template>

    <template v-else-if="footclass == '新闻动态'">
      <van-tabbar-item replace to="/policy" name="政策法规" icon="award-o"
        >政策法规</van-tabbar-item
      >
      <van-tabbar-item replace to="/news" name="新闻动态" icon="hot-o"
        >新闻动态</van-tabbar-item
      >
      <van-tabbar-item replace to="/information" name="通知公告" icon="volume-o"
        >通知公告</van-tabbar-item
      >

    </template>

    <template v-else>
      <van-tabbar-item replace to="/user" name="我的" icon="user-o"
        >我的</van-tabbar-item
      >
      <!-- <van-tabbar-item replace to="/institutions" name="机构设置" icon="cluster-o"
      >机构设置</van-tabbar-item
    >
    <van-tabbar-item replace to="/contact-us" name="联系我们" icon="phone-o"
      >联系我们</van-tabbar-item
    > -->
    </template>
  </van-tabbar>
</template>

<script>
export default {
  name: "Tab-bar",
  data() {
    return {
      fMenu: [
        { 关于我们: ["协会简介", "机构设置", "联系我们"] },
        { 新闻动态: ["政策法规", "新闻动态", "通知公告"] },
      ],
    };
  },
  computed: {
    active: {
      get() {
        return this.$store.state.footactive;
      },
      set(newValue) {
        return newValue;
      },
    },
    footclass: {
      get() {
        let fs = "";
        this.fMenu.map((item) => {
          for (let o in item) {
            if (item[o].includes(this.active)) {
              fs = o;
            }
          }
        });
        return fs;
      },
      set(newValue) {
        return newValue;
      },
    },
    // footclass() {
    //   return this.$store.state.footactive;
    // },
  },
  methods: {
    onChange(index) {
      this.$store.dispatch("saveHeadTitle", index);
      this.$store.dispatch("saveFootActive", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-bar {
  border-radius: 16px;
  background: #fff;
  box-shadow: -6px 4px 16px #edefee;
  padding: 6px 0;
  margin: 0 3%;
  width: 94%;
  bottom: 16px;
  &::after {
    border: 0;
  }

  .van-tabbar-item {
    &.van-tabbar-item--active {
      background: transparent;
    }
   /deep/ .van-icon {
      border-radius: 16px;
      background: #fff;
      box-shadow: -6px 4px 16px #edefee;
      padding: 6px;
    }
    
  }
}
</style>