<template>
  <div>
    <div class="navbar bg-header">
      <!-- <van-icon name="search" size="2rem" @click="searshow = true" /> -->

      <van-image
        class="logo"
        width="50%"
        :src="require('@/assets/wlogo.png')"
      />
      <van-icon name="wap-nav" size="2rem" @click="menushow = true" />
    </div>

    <van-popup
      v-model="menushow"
      position="left"
      get-container="html"
      class="overlay80vh"
    >
      <div class="nav-header">
        <a href="index.html">
          <img class="image-round" alt="" src="../assets/logo.png" />
          <span>广东省武术协会</span>
        <!-- <span>wushu@example.com</span> -->
        </a>
      </div>
      <MenuRed :Linkes="Linkes" />
    </van-popup>

    <van-popup v-model="searshow" position="top"
      ><van-search
        v-model="searvalue"
        show-action
        shape="round"
        background="#f1f2f4 "
        @search="onSearch"
        placeholder="请输入搜索关键词"
      >
        <template #action>
          <van-button @click="onSearch" round> 搜索 </van-button>
        </template>
      </van-search>
   
    </van-popup>
  </div>
</template>

<script>
import MenuRed from "./MenuRed";
export default {
  name: "HeaderRed",
  components: {
    MenuRed,
  },
  data() {
    return {
      menushow: false,
      searshow: false,
      searvalue: "",
      activeNames: ["1"],
      active: "0",
      Linkes: [
        {
          name: "首页",
          path: "/index",
          icon: "wap-home",
          isopen: false,
          children: [
             {
              name: "圆角阴影主题",
              path: "/index",
              icon: "shop-collect-o",
            },
             {
              name: "蓝色色主题",
              path: "/index-blue",
              icon: "brush-o",
            },
            {
              name: "黑色主题",
              path: "/index-black",
              icon: "shop-collect",
            },

            {
              name: "红色主题",
              path: "/index-red",
              icon: "shop-collect",
            },
            {
              name: "扁平主题",
              path: "/index-flat",
              icon: "shop",
            },
          ],
        },
        {
          name: "关于我们",
          path: "/about",
          icon: "friends",
          isopen: false,
          children: [
            {
              name: "协会简介",
              path: "/about",
              icon: "friends-o",
            },

            {
              name: "机构设置",
              path: "/institutions",
              icon: "cluster-o",
            },
            {
              name: "联系我们",
              path: "/contact-us",
              icon: "phone-o",
            },
          ],
        },
        {
          name: "新闻动态",
          path: "/news",
          icon: "volume",
          isopen: false,
          children: [
            {
              name: "政策法规",
              path: "/policy",
              icon: "award-o",
            },
            {
              name: "要闻动态",
              path: "/news",
              icon: "hot-o",
            },
            {
              name: "信息发布",
              path: "/information",
              icon: "volume-o",
            },
          ],
        },
        {
          name: "登录/注册",
          path: "/login",
          icon: "invition",
          isopen: false,
          children: [
            {
              name: "登录",
              path: "/login",
              icon: "upgrade",
            },
            {
              name: "注册",
              path: "/signup",
              icon: "sign",
            },
            {
              name: "忘记密码",
              path: "/forgot-password",
              icon: "question-o",
            },
            {
              name: "我的",
              path: "/user",
              icon: "user-o",
            },
          ],
        },
        {
          name: "会员服务",
          path: "/members",
          icon: "vip-card",
        },
        {
          name: "赛事服务",
          path: "/event",
          icon: "smile",
          isopen: false,
          children: [
            {
              name: "赛事",
              path: "/event",
              icon: "smile-o",
            },
            {
              name: "活动",
              path: "/event/activity",
              icon: "flag-o",
            },
            {
              name: "培训",
              path: "/event/training",
              icon: "calendar-o",
            },
          ],
        },
        {
          name: "教学服务",
          path: "/teaching",
          icon: "video",
        },
        {
          name: "多媒体区",
          path: "/teaching",
          icon: "live",
        },
        {
          name: "退出登录",
          path: "/logout",
          icon: "share",
        },
      ],
    };
  },
  methods: {
    onSearch() {},
  },
};
</script>
<style scoped lang="scss">
.logo {
  display: inline-block;
  overflow: hidden;
}
.overlay80vh {
  width: 74%;
  height: 100%;
  background: #E27184;
}
.navbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: #D01837;
  color: #fff;
}

.bg-header {
  z-index: 10;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
}

.image-round {
  box-shadow: -10px 10px 20px #000;
  border: 0 solid #EAA8B2;
  width: 30%;
  border-radius: 100%;
}

.nav-header {
  text-align: center;
  padding: 20px 0;
  background: #D01837;
  a {
    padding: 10px 0;
    color: #EAA8B2;
  }
  span {
    display: block;
  }
}
</style>
