<template>
  <news-list :resData="resData" />
</template>
<script>
import NewsList from "./news-list";
import { getNoticeList } from "@/api/index";
export default {
  name: "news-information",
  components: {
    NewsList,
  },
  data() {
    return {
      resData: [],
      queryParams: {
        sign:"wx",
        pageNum: 1,
        pageSize: 10,
        noticeClass: 1,
      },
    };
  },
  mounted() {
    this.getNoticeLists();
  },
  methods: {
    getNoticeLists() {
      getNoticeList(this.queryParams).then((res) => {
        if (res.code == 200) {
          this.resData = res.rows;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>