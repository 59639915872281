/**
 * Vuex-actions
 */
export default {
  saveUserInfo(context, userinfo){
    context.commit('setSaveUserInfo', userinfo)
  },
  saveCartCount(context, count){
    context.commit('setSaveCartCount', count)
  },
  saveHeadTitle(context, headtitle){
    context.commit('setSaveHeadTitle', headtitle)
  },
  saveFootActive(context, footactive){
    context.commit('setSaveFootActive', footactive)
  },
  saveLoading(context, LOADING){
    context.commit('setSaveLoading', LOADING)
  }
}