import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './pages/home'

import Index from './pages/index'
import IndexBlack from './pages/index-theme-black'
import IndexBlue from './pages/index-theme-blue'
import IndexRed from './pages/index-theme-red'
import IndexFlat from './pages/index-theme-flat'

// about
import AboutIndex from './pages/about/index'
import About from './pages/about/about'
import ContactUs from './pages/about/contact-us'
import Institutions from './pages/about/institutions'

// news 
import NewsIndex from './pages/news/index'
import News from './pages/news/news'
import Policy from './pages/news/policy';
import Information from './pages/news/information'
import NewsDetail from './pages/news/news-detail'

// user 
import Login from './pages/user/login'
import Signup from './pages/user/signup'
import ForgotPassword from './pages/user/forgot-password'
import User from './pages/user/index'
import My from './pages/user/my'
import MyUser from './pages/user/myuser'
import Members from './pages/user/members'
import MembersApply from './pages/user/members-apply'
import MembersDetaily from './pages/user/members-detail'
import MembersCard from './pages/user/members-card'
import MembersPay from './pages/user/members-pay'
import PayGateWay from './pages/user/payGateWay'


// service 
// import Login from './pages/login'

Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta:{index:0},//meta对象的index用来定义当前路由的层级,由小到大,由低到高
      redirect: './index',
      children: [
        {
          path: '/index',
          name: 'index',
          component: Index,
          meta:{index:1}
        },
        {
          path: '/index-blue',
          name: 'index-blue',
          component: IndexBlue,
        },
        {
          path: '/index-black',
          name: 'index-black',
          component: IndexBlack,
        },
        {
          path: '/index-red',
          name: 'index-red',
          component: IndexRed,
        },
        {
          path: '/index-flat',
          name: 'index-flat',
          component: IndexFlat,
        },
      ]
    },
    {
      path: '/',
      name: '',
      component: AboutIndex,
      meta:{index:1},
      children:[
        {
          path: '/about',
          name: 'about',
          component: About,
          meta:{index:2},
        },
        {
          path: '/contact-us',
          name: 'contact-us',
          component: ContactUs,
          meta:{index:2},
          
        },
        {
          path: '/institutions',
          name: 'institutions',
          component: Institutions,
          meta:{index:2},
        }
      ]
    },
    {
      path: '/',
      name: '',
      component: NewsIndex,
      children:[
        {
          path: '/news',
          name: 'news',
          component: News,
        },
        {
          path: '/policy',
          name: 'policy',
          component: Policy,
        },
        {
          path: '/information',
          name: 'information',
          component: Information,
        },
        {
          path: '/news-detail/:id',
          name: 'news-detail',
          props:true,
          component: NewsDetail,
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
    },
    {
      path: '/',
      name: '',
      component: User,
      meta: {
        requireAuth: true 
       },
      children:[
        {
          path: '/user',
          name: 'user',
          component: My,
        },
        {
          path: '/myuser',
          name: 'myuser',
          component: MyUser,
        },
        {
          path: '/members',
          name: 'members',
          component: Members,
          meta: {
            requireAuth: true
           },
        },
        {
          path: '/members-detail',
          name: 'members-detail',
          component: MembersDetaily,
        },
        {
          path: '/members-apply',
          name: 'members-apply',
          component: MembersApply,
        },
        {
          path: '/members-card',
          name: 'members-card',
          component: MembersCard,
        },
        {
          path: '/members-pay/:id',
          name: 'members-pay',
          props:true,
          component: MembersPay,
        },
        {
          path: '/payGateWay',
          name: 'payGateWay',
          props:true,
          component: PayGateWay,
        },
        
      ]
    },
  ]
})

