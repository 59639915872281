import Vue from 'vue'
import Vant from 'vant';
import { Lazyload } from 'vant';

import 'vant/lib/index.css';
import "./assets/scss/reset.scss";
import "./assets/scss/base.scss";

import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'


import App from './App.vue'

Vue.config.productionTip = false

Vue.use(Vant);
Vue.use(Lazyload)
Vue.use(VueAxios, axios)

import base from "@/util/base";
Vue.use(base);
/**
 * 按需加载组件
 */
// import { Button } from 'vant';
// Vue.use(Button);

// 判断是否需要登录权限 以及是否登录
router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.requireAuth)) {// 判断是否需要登录权限
    //  window.sessionStorage.setItem("token", res.token);
    // localStorage.getItem('token')
    if (window.sessionStorage.getItem('token')) {// 判断是否登录
      next()
    } else {// 没登录则跳转到登录界面
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
