<template>
  <div class="content">
    <div class="content-title">广东省武术协会</div>
    <div class="content-text">
      
      <p v-html="about.introduction"></p>
      <img :src="loadUrl(about.logo)"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  props: {
    about: Object,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>