<template>
  <div>
    <nav-bar></nav-bar>
    <router-view></router-view>
    <Loading v-if="LOADING" />
    <tab-bar></tab-bar>
  </div>
</template>

<script>
import NavBar from "@/components/Navbar";
import TabBar from "@/components/Tabbar";
import Loading from "@/components/Loading";
import { mapState } from "vuex";
export default {
  name: "user-home",
  components: {
    NavBar,
    TabBar,
    Loading,
  },
  computed: {
    ...mapState(["LOADING"]),
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>