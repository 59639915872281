<template>
  <div class="login">
    <div class="container">
      <div class="login-top">
        <img class="image-round" alt="" src="../../assets/logo.png" />
        <p>填写详细信息并创建您的帐户</p>
      </div>

      <van-tabs class="tab-bar">
        <van-tab title="个人注册">
          <van-form @submit="onResigter(1)" class="base-form">
            <van-field
              v-model="personinfo.name"
              name="姓名"
              placeholder="请输入姓名"
              label="姓名"
              maxlength="6"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
              v-model="personinfo.identityCode"
              name="身份证"
              placeholder="请输入身份证"
              label="身份证"
              maxlength="18"
              :formatter="formatterID"
              :error-message="errorTextid"
              :rules="[{ required: true, message: '请填写身份证' }]"
            />
            <van-field
              v-model="personinfo.phone"
              name="手机号码"
              maxlength="11"
              placeholder="请输入手机号码"
              label="手机号码"
              :formatter="formatter"
              :error-message="errorText"
              :rules="[{ required: true, message: '请填写手机号码' }]"
            />

            <van-field
              v-model="personinfo.code"
              center
              clearable
              maxlength="6"
              label="短信验证码"
              placeholder="请输入短信验证码"
              :rules="[{ required: true, message: '请填写验证码' }]"
            >
              <template #button>
                <van-button size="small" class="btn-send"
                  >发送验证码</van-button
                >
              </template>
            </van-field>

            <van-field
              v-model="personinfo.password"
              name="用户密码"
              placeholder="请输入用户登录密码"
              label="用户密码"
              right-icon="closed-eye"
              maxlength="18"
              :rules="[{ required: true, message: '请填写密码' }]"
            />
            <van-field
              v-model="personinfo.passwordt"
              name="确认密码"
              placeholder="请再次输入密码"
              label="确认密码"
              maxlength="18"
              :rules="[{ required: true, message: '请填写密码' }]"
            />

            <van-button
              round
              block
              class="btn-true"
              :disabled="!checked"
              native-type="submit"
              >注册</van-button
            >
          </van-form>
        </van-tab>
        <van-tab title="单位注册">
          <van-form @submit="onResigter(2)" class="base-form">
            <van-field
              v-model="orginfo.name"
              name="单位名称"
              placeholder="请输入单位名称"
              label="单位名称"
              :rules="[{ required: true, message: '请填写单位名称' }]"
              maxlength="30"
            />
            <van-field
              v-model="orginfo.creditCode"
              name="统一社会信用代码"
              placeholder="请输入统一社会信用代码"
              label="统一社会信用代码"
              :rules="[
                { required: true, message: '请填写统一社会信用代码' },
                {
                  pattern: /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/,
                  message: '请输入正确的统一社会信用代码',
                },
              ]"
              maxlength="18"
            />
            <van-field
              v-model="orginfo.principal"
              name="联系人"
              placeholder="请输入联系人"
              label="联系人"
              :rules="[{ required: true, message: '请填写联系人' }]"
            />
            <van-field
              v-model="orginfo.phone"
              name="联系电话"
              placeholder="请输入联系电话"
              label="联系电话"
              maxlength="11"
              :rules="[
                { required: true, message: '请填写联系电话' },
                {
                  pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                  message: '请输入正确的手机号码',
                },
              ]"
            />
            <van-field
              v-model="orginfo.code"
              center
              clearable
              maxlength="6"
              label="短信验证码"
              placeholder="请输入短信验证码"
              :rules="[{ required: true, message: '请填写验证码' }]"
            >
              <template #button>
                <van-button size="small" class="btn-send"
                  >发送验证码</van-button
                >
              </template>
            </van-field>

            <van-field
              v-model="orginfo.password"
              name="用户密码"
              placeholder="请输入用户登录密码"
              label="用户密码"
              right-icon="closed-eye"
              :rules="[{ required: true, message: '请填写密码' }]"
              maxlength="18"
            />
            <van-field
              v-model="orginfo.passwordt"
              name="确认密码"
              placeholder="请再次输入密码"
              label="确认密码"
              maxlength="18"
              :rules="[{ required: true, message: '请填写密码' }]"
            />

            <van-button
              round
              block
              :disabled="!checked"
              native-type="submit"
              class="btn-true"
              >注册</van-button
            >
          </van-form>
        </van-tab>

        <div class="item-bar">
          <van-checkbox v-model="checked" checked-color="#fe051b"
            >我已阅读并同意</van-checkbox
          ><span class="to-link" @click="show = true"
            >《相关服务条款和隐私政策》</span
          >
        </div>
        <div class="item-bar">
          <span class="span-link"
            >已经有账号？<router-link to="/login" class="Black"
              >登录</router-link
            ></span
          >
        </div>
      </van-tabs>
    </div>

    <van-popup
      v-model="show"
      round
      position="bottom"
      closeable
      close-icon="close"
    >
      <div class="statement">
        <h2 class="statement-title">《相关服务条款和隐私政策》</h2>
        <div class="statement-content">
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { register } from "@/api/user";
export default {
  name: "signup",
  data() {
    return {
      personinfo: {
        sign: "mobile",
        registerType: 1,
        name: "",
        identityCode: "",
        phone: "",
        code: "",
        password: "",
        passwordt: "",
        userInfo: {
          identityCode: "",
          password: "",
          phonenumber: "",
          userName: "",
        },
      },
      orginfo: {
        sign: "mobile",
        registerType: 2,
        name: "",
        creditCode: "",
        principal: "",
        phone: "",
        code: "",
        password: "",
        passwordt: "",
        userInfo: {
          userName: " ",
          phonenumber: " ",
          password: " ",
        },
      },
      checked: false,
      errorText: "",
      errorTextid: "",
      show: false,
    };
  },
  methods: {
    onResigter(registerType) {
      var self = this;

      if (registerType == 1) {
        self.$set(self.personinfo.userInfo, "userName", self.personinfo.name);
        self.$set(
          self.personinfo.userInfo,
          "phonenumber",
          self.personinfo.phone
        );
        self.$set(
          self.personinfo.userInfo,
          "password",
          self.personinfo.password
        );
        self.$set(
          self.personinfo.userInfo,
          "identityCode",
          self.personinfo.identityCode
        );
      } else {
        self.$set(self.orginfo.userInfo, "userName", self.orginfo.name);
        self.$set(self.orginfo.userInfo, "phonenumber", self.orginfo.phone);
        self.$set(self.orginfo.userInfo, "password", self.orginfo.password);
      }
      let param = registerType == 1 ? self.personinfo : self.orginfo;

      register(param).then((res) => {
        if (res.code == 200) {
          self.$router.push("/login");
        } else {
          this.$toast(res.msg);
        }
      });
    },
    formatter(val) {
      // const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
      const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;

      if (!val) return "";
      if (!reg.test(val)) {
        this.errorText = "请输入正确的手机号码";
      } else {
        this.errorText = "";
      }
      return val;
    },
    formatterID(val) {
      // const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
      // const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
      const reg = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/;

      if (!val) return "";
      if (!reg.test(val)) {
        this.errorTextid = "请输入正确的证件号";
      } else {
        this.errorTextid = "";
      }
      return val;
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  height: 100vh;
  background: linear-gradient(to bottom, #fbfffa, #fff);
  .container {
    padding-bottom: 40px;
    .login-top {
      text-align: center;
      padding: 10px;
      .image-round {
        box-shadow: -20px 20px 50px #ececec;
        border: 6px solid #fbfffa;
        border-radius: 100%;
      }
      img {
        width: 30%;
      }
    }
    .login-title {
      padding: 10px;
      border-radius: 30px;
      line-height: 24px;
      margin: 5px auto;
      // background-color: #fe051b;
      color: #fbfffa;
      text-align: center;
    }
  }
}
.base-form {
  .van-cell {
    margin: 0px 3% 16px;
    width: 94%;
    padding: 10px 20px;
    background: #fbfffa;
    border: solid 2px #fff;
    border-radius: 32px;
    box-shadow: -6px 6px 16px #eff0f5;
    &::after {
      border-width: 0;
    }
  }
}

.btn-send {
  background: #fff;
  border: solid #f1f2f4 1px;
  color: #444;
  border-radius: 16px;
  box-shadow: -6px 6px 16px #f1f2f4;
}

.tab-bar {
  width: 100%;
  /deep/ .van-tabs__wrap {
    padding: 10px 0 20px;
  }
  /deep/ .van-tabs__nav {
    border-radius: 22px;
    background: #f7f8fc;
    width: 60%;
    margin: 0 auto;
    padding-bottom: 0;
    box-shadow: -10px 6px 16px #f1f2f4;
    .van-tab {
      padding: 15px 0;
      color: #444;
      border-radius: 22px;
      &.van-tab--active {
        background: #fff;
        color: #1a1613;
        border: solid 2px #f1f2f4;
        box-shadow: 0 0 6px #f1f2f4;
      }
    }
    .van-tabs__line {
      display: none;
    }
  }
}
</style>