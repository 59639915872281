<template>
  <div class="content">
    <div class="pay-card">
      <h2>{{ order.goodsName }}</h2>
      <span class="pay-item">订单号：{{ order.backOrder }}</span>
      <span class="pay-item"
        >订单类型：{{ order.orderType == 1 ? "会员订单" : "赛事订单" }}</span
      >
      <span class="pay-item">创建时间：{{ order.createTime }}</span>
      <span class="pay-item"
        >订单金额：<i class="Red f20">￥ {{ order.orderPrice }} 元</i></span
      >
    </div>
    <div class="pay-type">
      <h2>支付方式</h2>
      <van-radio-group v-model="payType">
        <van-radio :name="1" checked-color="#fe051b">支付宝 </van-radio>
        <van-radio :name="2" checked-color="#fe051b">微信 </van-radio>
      </van-radio-group>

      <div class="pay-bar">
        <span class="pay-price"
          >总价：<i class="Red f20">{{ order.orderPrice }} </i>元</span
        >
        <van-button color="#fe051b" @click="payOrder">确认支付</van-button>
      </div>

      <div v-html="from" ref="alipayWap">{{from}}</div>
    </div>
  </div>
</template>
<script>
import { getOrderById, mobileAlipay, mobileWxpay } from "@/api/user";
export default {
  name: "members-pay",
  props: {
    id: String,
  },
  data() {
    return {
      order: [],
      payType: 1,
      from: null,
    };
  },
  mounted() {
    this.getOrderByIds();
  },
  methods: {
    getOrderByIds() {
      const self = this
      let id = self.id;
      self.$store.commit("showLoading");
      getOrderById({ id }).then((res) => {
        self.$store.commit("hideLoading");
        if (res.code == 200) {
          self.order = res.data;
          self.orderId = res.data.backOrder;
        } else {
          self.$toast(res.msg);
        }
        
      });
    },
    payOrder() {
      const self = this
      let id = self.id;
      let type = self.payType;
      self.$store.commit("showLoading");
      if (type == 1) {
        mobileAlipay({ id }).then((res) => {
          self.from = res;
          self.$nextTick(() => {
            self.$refs.alipayWap.children[0].submit();
            self.$store.commit("hideLoading");
          });
          //   let routerData = self.$router.resolve({path:'/payGateWay',query:{ htmlData: res}})
          //  // 打开新页面
          //   window.open(routerData.href, '_blank')
        });
      } else {
        mobileWxpay({ id }).then((res) => {
          self.$store.commit("hideLoading");
          if (res.code == 200) {
          } else {
            self.$toast(res.msg);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pay-card,
.pay-type {
  margin-top: 16px;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 0 16px rgba($color: #000000, $alpha: 0.1);
  .h2 {
    width: 100%;
  }
  .pay-item {
    display: block;
    padding: 8px;
    margin-bottom: 8px;
    background: rgba($color: #000000, $alpha: 0.03);
    i {
      font-style: normal;
    }
  }
  .van-radio {
    margin-top: 16px;
  }
  .pay-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    background: rgba($color: #000000, $alpha: 0.03);
    .pay-price {
      display: inline-block;
      padding: 8px;
      i {
        font-style: normal;
      }
    }
  }
}
</style>