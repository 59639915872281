<template>
  <div v-html="payGateWay" ref="alipayWap">{{ payGateWay }}</div>
</template>

<script>
export default {
  name: "payGateWay",
  data() {
    return {
      payGateWay: "",
    };
  },
  mounted() {
    let form = this.$route.query.htmlData;
    this.payGateWay = form;
    this.$nextTick(() => {
      this.$refs.alipayWap.children[0].submit();
    });
  },
};
</script>