<template>
  <div class="content">
    <div class="content-title">广东省武术协会</div>
    <div class="content-text">
      <ul class="institutions-list">
        <li v-for="(item, index) in about.structure" :key="index">
          <span class="grade">{{ item.orgRole}} :</span>
          <span class="uName">{{ item.personnel}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "institutions",
  props:{
    about:Object
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss" scoped>
.institutions-list{
  padding: 16px;
  li{
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    .grade{
      display: inline-block;
      // flex-shrink: 0;
      // width: 80px;
      padding-right: 16px;
    }
     .uName{
      display: inline-block;
      flex: 1;
      color: #999;
    }
  }
}
</style>