/**
 * Vuex-mutations
 */
export default {
  setSaveUserInfo(state, userinfo) {
    state.userinfo = userinfo
  },
  setSaveCartCount(state, count) {
    state.count = count
  },
  setSaveHeadTitle(state, headtitle) {
    state.headtitle = headtitle
  },
  setSaveFootActive(state, footactive) {
    state.footactive = footactive
  },
  setSaveLoading(state, LOADING) {
    state.LOADING = LOADING
  },
  showLoading(state) {
    state.LOADING = true
  },
  hideLoading(state) {
    state.LOADING = false
  }

}