<template>
  <div>
    <nav-bar></nav-bar>
    <router-view></router-view>
    <tab-bar></tab-bar>
  </div>
</template>

<script>
import NavBar from '@/components/Navbar'
import TabBar from '@/components/Tabbar'
export default {
  name: "News-news",
  components: {
    NavBar,
    TabBar
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss" scoped>
#app .content{
  padding-left:0 ;
  padding-right:0 ;
}
</style>