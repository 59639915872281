<template>
  <div class="login">
    <div class="content">
      <div class="members-apply-table">
        <!-- 个人 -->
        <!-- <template v-if="userType == 1">
          <h2 class="members-apply-title">广东省武术协会个人会员入会申请表</h2>
          <van-form @submit="onSubmit" class="base-form">
            <van-field
              v-model="personApplyInfo.userName"
              name="姓名"
              placeholder="请输入姓名"
              clearable
              label="姓名"
              :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field name="radio" label="单选框">
              <template #input>
                <van-radio-group
                  v-model="personApplyInfo.sex"
                  direction="horizontal"
                >
                  <van-radio name="1" value="1">男</van-radio>
                  <van-radio name="2" value="2">女</van-radio>
                </van-radio-group>
              </template>
            </van-field>

            <van-field
              readonly
              clickable
              name="datetime-picker"
              :value="personApplyInfo.birthDate"
              label="出生日期"
              right-icon="birthday-cake-o"
              placeholder="点击出生日期"
              @click="showBirth = true"
            />


              <van-popup v-model="showBirth" position="bottom">
                <van-datetime-picker
                  v-model="currentDate"
                  type="date"
                  title="选择出生日期"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onBirthDate"
                />
              </van-popup>

            <van-field
              v-model="personApplyInfo.nation"
              clearable
              name="民族"
              placeholder="请输入民族"
              label="民族"
            />

            <van-field
              v-model="personApplyInfo.domicilePlace"
              clearable
              name="户口所在地"
              placeholder="请输入户口所在地"
              label="户口所在地"
            />

            <van-field
              v-model="personApplyInfo.job"
              clearable
              name="职称"
              placeholder="请输入职称"
              label="职称"
            />

            <van-field
              v-model="personApplyInfo.literacyLevels"
              clearable
              name="文化程度"
              placeholder="请输入文化程度"
              label="文化程度"
            />

            <van-field
              readonly
              clickable
              right-icon="gem-o"
              name="MemType"
              :value="personApplyInfo.MemType"
              label="会员类别"
              placeholder="会员类别"
              @click="MemTypeShow = true"
            />
            <van-popup v-model="MemTypeShow" position="bottom">
              <van-picker
                show-toolbar
                :columns="MemTypeColumns"
                @confirm="onMemType"
                @cancel="MemTypeShow = false"
              />
            </van-popup>

            <van-field
              v-model="personApplyInfo.specialityWuShu"
              name="武术专长"
              placeholder="请输入武术专长"
              clearable
              label="武术专长"
              :rules="[{ required: true, message: '请填写武术专长' }]"
            />

            <van-field
              v-model="personApplyInfo.IDCardNo"
              clearable
              name="身份证号码"
              placeholder="请输入身份证号码"
              label="身份证号码"
              :rules="[{ required: true, message: '请填写身份证号码' }]"
            />
            <van-field
              v-model="personApplyInfo.ocupertino"
              clearable
              name="现工作单位及职务"
              placeholder="请输入现工作单位及职务"
              label="现工作单位及职务"
            />

            <van-field
              v-model="personApplyInfo.address"
              name="通讯地址"
              placeholder="请输入通讯地址"
              label="通讯地址"
            />
            <van-field
              v-model="personApplyInfo.postcode"
              name="邮政编码"
              placeholder="请输入邮政编码"
              label="邮政编码"
            />

            <van-field
              v-model="personApplyInfo.phone"
              name="手机号码"
              placeholder="请输入手机号码"
              clearable
              label="手机号码"
              :rules="[{ required: true, message: '请填写手机号码' }]"
            />

            <van-field
              v-model="personApplyInfo.papersCode"
              clearable
              name="个人简历"
              placeholder="请输入个人简历"
              label="个人简历"
            />

            <van-field name="uploader" label="照片（1寸免冠）上传">
              <template #input>
                <van-uploader
                  v-model="personApplyInfo.avatar"
                  multiple
                  :after-read="afterRead"
                  :max-count="1"
                />
              </template>
            </van-field>

            <van-button round block class="btn-true" native-type="submit"
              >提交申请</van-button
            >
          </van-form>
        </template> -->

        <!-- 单位 -->
        <!-- <template v-else>
          <h2 class="members-apply-title">广东省武术协会团体理事单位申报表</h2>
          <van-form @submit="onSubmit" class="base-form">
            <van-field
              v-model="orgApplyInfo.orgNameCN"
              name="单位名称（中文）"
              placeholder="请输入单位名称（中文）"
              clearable
              type="textarea"
              rows="2"
              autosize
              label="单位名称（中文）"
              :rules="[{ required: true, message: '请填写单位名称（中文）' }]"
            >
              <template slot="label"
                >单位名称<br />（ 中文 ）</template
              ></van-field
            >

            <van-field
              v-model="orgApplyInfo.orgNameEN"
              clearable
              type="textarea"
              rows="2"
              autosize
              name="单位名称（英文）"
              placeholder="请输入单位名称（英文）"
              label="单位名称（英文）"
              :rules="[{ required: true, message: '请填写单位名称（英文）' }]"
            >
              <template slot="label"
                >单位名称<br />（英 文）</template
              ></van-field
            >

            <van-field
              v-model="orgApplyInfo.unitSizeOrgMembers"
              clearable
              type="textarea"
              rows="2"
              autosize
              name="单位规模/会员人数"
              placeholder="请输入单位规模/会员人数"
              label="单位规模/会员人数"
              :rules="[{ required: true, message: '请填写单位规模/会员人数' }]"
            >
              <template slot="label"
                >单位规模 /<br />会员人数</template
              ></van-field
            >

            <van-field
              v-model="orgApplyInfo.registeredCapital"
              clearable
              name="注册资金"
              placeholder="请输入注册资金"
              label="注册资金（万元）"
              :rules="[{ required: true, message: '请填写注册资金' }]"
            >
              <template slot="label"
                >注册资金<br />（万元）</template
              ></van-field
            >

            <van-field
              v-model="orgApplyInfo.unicode"
              clearable
              type="textarea"
              rows="2"
              autosize
              name="统一代码"
              placeholder="除事业单位外填写，填写时请删除"
              label="统一代码"
              :rules="[{ required: true, message: '请填写统一代码' }]"
            />

            <van-field
              v-model="orgApplyInfo.introduction"
              clearable
              type="textarea"
              rows="2"
              autosize
              name="单位简介"
              placeholder="请输入单位简介"
              label="单位简介"
              :rules="[{ required: true, message: '请填写单位简介' }]"
            />

            <van-field
              v-model="orgApplyInfo.webSite"
              clearable
              name="单位网址"
              placeholder="请输入单位网址"
              label="单位网址"
            />

            <van-field
              v-model="orgApplyInfo.weChatOfficialAccount"
              clearable
              name="微信公众号"
              placeholder="请输入微信公众号"
              label="微信公众号"
            />

            <van-field
              v-model="orgApplyInfo.emall"
              name="联系邮箱"
              placeholder="请输入联系邮箱"
              label="联系邮箱"
              :rules="[{ required: true, message: '请填写联系邮箱' }]"
            />

            <van-field
              v-model="orgApplyInfo.CorpName"
              name="法人姓名"
              placeholder="请输入法人姓名"
              label="法人姓名"
              :rules="[{ required: true, message: '请填写法人姓名' }]"
            />

            <van-field
              v-model="orgApplyInfo.CorpNameTel"
              name="法人联系电话"
              placeholder="请输入法人联系电话"
              clearable
              label="法人联系电话"
              :rules="[{ required: true, message: '请填写手机号码' }]"
            />

            <van-field
              v-model="orgApplyInfo.linkman"
              name="联系人姓名"
              placeholder="请输入联系人姓名"
              label="联系人姓名"
              :rules="[{ required: true, message: '请填写联系人姓名' }]"
            />

            <van-field
              v-model="orgApplyInfo.phone"
              name="联系人电话"
              placeholder="请输入法联系人电话"
              clearable
              label="联系人电话"
              :rules="[{ required: true, message: '请填写联系人电话' }]"
            />

            <van-field
              readonly
              clickable
              right-icon="gem-o"
              name="grade"
              :value="orgApplyInfo.grade"
              label="团体理事单位"
              placeholder="团体理事单位"
              required
              @click="showGrade = true"
            />
            <van-popup v-model="showGrade" position="bottom">
              <van-picker
                show-toolbar
                :columns="gradeColumns"
                @confirm="onGrade"
                @cancel="showGrade = false"
              />
            </van-popup>
            <van-field
              name="uploader"
              label="社会团体法人登记证/企业法人营业执照"
            >
              <template #input>
                <van-uploader
                  v-model="orgApplyInfo.avatar"
                  multiple
                  required
                  :after-read="afterRead"
                  :max-count="2"
                />
              </template>
            </van-field>

            <van-button round block class="btn-true" native-type="submit" :disabled="!checked"
              >提交申请</van-button
            >
          </van-form>
        </template> -->

        <template v-if="userType == 1">
          <h2 class="members-apply-title">广东省武术协会个人会员入会申请表</h2>
          <template v-if="state == 2">
            <ul class="memberInfos">
              <li v-for="(value, name) in memberInfo" :key="name">
                <span class="m-name">{{ name }}：</span>
                <span class="m-value">{{ value }} </span>
              </li>
            </ul>
          </template>
          <van-form v-else @submit="onSubmit" class="base-form">
            <van-field
              readonly
              clickable
              right-icon="gem-o"
              name="MemType"
              :value="person.memberName"
              label="会员名称"
              placeholder="会员名称"
              @click="MemTypeShow = true"
            />
            <van-popup v-model="MemTypeShow" position="bottom">
              <van-picker
                show-toolbar
                :columns="MemTypeColumns"
                @confirm="onMemType"
                @cancel="MemTypeShow = false"
              />
            </van-popup>

            <van-field
              v-model="person.goodsPrice"
              name="会员费用"
              placeholder="会员费用"
              clearable
              label="会员费用"
              readonly
            />

            <van-field
              v-model="person.sourceOrgName"
              is-link
              @click="showUnit = true"
              name="所属单位"
              placeholder="所属单位"
              clearable
              autocomplete="off"
              label="所属单位"
            />

            <van-popup
              v-model="showUnit"
              position="bottom"
              :style="{ height: '60%' }"
            >
              <van-picker
                show-toolbar
                :columns="UniteColumns"
                @confirm="onUnit"
                @cancel="showUnit = false"
              />
            </van-popup>

            <van-cell-group v-for="item in customForms" :key="item.formId">
              <van-field
                v-model="person[item.vModel]"
                :type="item.type || 'text'"
                :label="item.label"
                :maxlength="item.maxlength"
                :rules="[
                  { required: true, message: item.placeholder },
                  {
                    pattern: [...item.regList].pattern,
                    message: [...item.regList].message,
                  },
                ]"
                :readonly="item.readonly"
                :placeholder="item.placeholder"
              />
            </van-cell-group>
            <van-button
              round
              block
              class="btn-true"
              :disabled="!checked"
              native-type="submit"
              >提交申请</van-button
            >
          </van-form>
        </template>
        <template v-else>
          <h2 class="members-apply-title">广东省武术协会团体理事单位申报表</h2>
          <van-form @submit="onSubmit" class="base-form">
            <van-field
              readonly
              clickable
              right-icon="gem-o"
              name="MemType"
              :value="person.MemType"
              label="会员类别"
              placeholder="会员类别"
              @click="MemTypeShow = true"
            />
            <van-popup v-model="MemTypeShow" position="bottom">
              <van-picker
                show-toolbar
                :columns="MemTypeColumns"
                @confirm="onMemType"
                @cancel="MemTypeShow = false"
              />
            </van-popup>

            <van-field
              v-model="person.MemPrice"
              name="会员费用"
              placeholder="会员费用"
              clearable
              label="会员费用"
              readonly
            />

            <van-field
              v-model="person.unit"
              is-link
              @click="showUnit = true"
              name="所属单位"
              placeholder="所属单位"
              clearable
              autocomplete="off"
              label="所属单位"
            />

            <van-popup
              v-model="showUnit"
              position="bottom"
              :style="{ height: '60%' }"
            >
              <van-picker
                show-toolbar
                :columns="UniteColumns"
                @confirm="onUnit"
                @cancel="showUnit = false"
              />
            </van-popup>

            <van-cell-group v-for="item in customForms" :key="item.formId">
              <van-field
                v-model="person[item.vModel]"
                :type="item.type || 'text'"
                :label="item.label"
                :maxlength="item.maxlength"
                :rules="[
                  { required: true, message: item.placeholder },
                  {
                    pattern: [...item.regList].pattern,
                    message: [...item.regList].message,
                  },
                ]"
                :readonly="item.readonly"
                :placeholder="item.placeholder"
              />
            </van-cell-group>

            <!--  
              <van-empty description="描述文字" />
              item.regList[0]
               :required="item.required"
               {...item.regList}
                {
                  message: '手机号格式错误',
pattern: /^1(3|4|5|7|8|9)\d{9}$/
                }
               {
                  pattern: /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/,
                  message: '请输入正确的统一社会信用代码',
                },
      
             -->

            <van-button round block class="btn-true" native-type="submit"
              >提交申请</van-button
            >
          </van-form>
        </template>

        <div class="item-bar flex-sb" v-if="state == 2">
          <!-- <van-button icon="checked" type="primary" > 已审核 </van-button> -->

          <span class="item-state flex-sb"
            ><van-icon name="checked" color="#07c160" size="2rem" /> 已审核
          </span>
          <!-- <span class="item-btn"><router-link to="/members-pay/222" class="Black">
              <van-button round color="#fe051b" type="primary"
                >去支付</van-button
              ></router-link
            ></span> -->
        </div>

        <div class="item-bar" v-else>
          <van-checkbox v-model="checked" clickable checked-color="#fe051b"
            >我已阅读并同意</van-checkbox
          ><span class="to-link" @click="show = true"
            >《相关服务条款和隐私政策》</span
          >
        </div>
      </div>
    </div>

    <van-popup
      v-model="show"
      round
      position="bottom"
      closeable
      close-icon="close"
    >
      <div class="statement">
        <h2 class="statement-title">《相关服务条款和隐私政策》</h2>
        <div class="statement-content">
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
          <p>
            《相关服务条款和隐私政策》
            相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策相关服务条款和隐私政策
          </p>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import {
  checkUserIsOrgMember,
  queryJoinOrgApplyDetail,
  queryCenterApplyMemberList,
  getCustomForm,
  queryAssociationList,
  centerApplyJoinOrg,
} from "@/api/user";
export default {
  name: "myuser",
  data() {
    return {
      user: {},
      userType: 0,
      state: 0,
      parameters: {},
      orgApplyInfo: {
        orgNameCN: "",
        orgNameEN: "",
        unitSizeOrgMembers: "",
        registeredCapital: "",
        unicode: "",
        introduction: "",
        webSite: "",
        weChatOfficialAccount: "",
        emall: "",
        CorpName: "",
        CorpNameTel: "",
        linkman: "",
        phone: "",
        grade: "",
        avatar: [],
      },
      personApplyInfo: {
        userName: "",
        sex: 1,
        birthDate: "",
        emall: "",
        nation: "",
        domicilePlace: "",
        job: "",
        literacyLevels: "",
        MemType: "",
        specialityWuShu: "",
        IDCardNo: "",
        ocupertino: "",
        address: "",
        postcode: "",
        phone: "",
        resume: "",
        avatar: [],
      },
      person: {
        sign: "wx",
        memberName: "",
        memberId: 0,
        goodsPrice: "",
        sourceOrgName: "",
        sourceOrgId: 0,
        code: {},
      },
      associationList: [],
      showUnit: false,
      UniteColumns: [],
      customForms: [],
      editMember: [],
      codeMap: {},
      memberApply: {},
      memberInfo: {},

      showBirth: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      showArea: false,
      areaList, // 数据格式见 Area 组件文档

      MemTypeShow: false,
      MemTypeColumns: [],
      MemberList: [],
      MemPrice: 0,
      MemParms: {
        sign: "wx",
        memberId: 0,
        type: 1,
      },

      showGrade: false,
      gradeColumns: [
        "副会长单位：80000元/年",
        "理事单位：50000元/年 ",
        "监事单位：20000元/年",
      ],

      checked: false,
      show: false,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  mounted() {
    let self = this;
    self.userType = self.userinfo.userType;
    self.$store.commit("showLoading");
    checkUserIsOrgMember({ sign: "wx" }).then((res) => {
      if (res.code == 200) {
        const isStaute = (self.state = res.data.isOrgMember);
        if (isStaute == 0) {
          self.getMembershipFee();
          self.queryAssociationLists();
        } else if (isStaute == 1) {
          self.queryJoinOrgApplyDetails();
          setTimeout( self.queryAssociationLists(),300 )
          self.getMembershipFee();
        } else if (isStaute == 2) {
          self.queryJoinOrgApplyDetails();
          self.getMembershipFee();
        }
      } else {
        self.$toast(res.msg);
      }
      self.$store.commit("hideLoading");
    });
  },
  methods: {
    getMembershipFee() {
      let self = this;
      queryCenterApplyMemberList({ sign: "wx" }).then((res) => {
        if (res.code == 200) {
          self.MemberList = res.data;
          res.data.map((item) => {
            self.MemTypeColumns.push(item.memberName);
          });
          // self.MemParms.memberId = res.data[0].id
          self.getCustomForms();
        } else {
          self.$toast(res.msg);
        }
      });
    },
    // 会员类别
    onMemType(value) {
      let self = this;
      let cItem = self.MemberList.filter((item) => item.memberName == value);

      if (cItem[0].goodsPrice) {
        self.MemPrice = self.float_calculator(
          "add",
          cItem[0].goodsPrice,
          self.float_calculator(
            "add",
            cItem[0].makePrice || 0,
            cItem[0].rebatePrice || 0
          )
        );
      }

      self.$set(self.person, "memberName", value);
      self.$set(self.person, "memberId", cItem[0].id);
      self.$set(self.person, "goodsPrice", self.MemPrice);
      self.MemTypeShow = false;
    },
    onUnit(value) {
      let self = this;
      let cItem = self.associationList.filter((item) => item.name == value);
      self.person.sourceOrgName = value;
      self.person.sourceOrgId = cItem[0].id;
      self.showUnit = false;
    },
    getCustomForms() {
      let self = this;
      getCustomForm({ sign: "wx" }).then((res) => {
        if (res.code == 200) {
          self.customForms = res.data;
          self.customForms.map((item) => {
            self.$set(self.person, item.vModel, item.defaultValue || "");
            self.$set(self.person.code, item.vModel, item.defaultValue || "");

            if (self.state == 1 || self.state == 2) {
              for (let key in self.codeMap) {
                if (item.vModel == key) {
                  item.defaultValue = self.codeMap[key];
                  self.$set(self.person, item.vModel, self.codeMap[key]);
                  self.$set(self.person.code, item.vModel, self.codeMap[key]);
                  self.$set(self.memberInfo, item.label, self.codeMap[key]);
                }
              }
            }
          });
        } else {
          self.$toast(res.msg);
        }
      });
    },
    queryAssociationLists() {
      let self = this;
      queryAssociationList({}).then((res) => {
        if (res.code == 200) {
          self.associationList = res.data;
          self.associationList.map((item) => {
            self.UniteColumns.push(item.name);
          });
        } else {
          self.$toast(res.msg);
        }
      });
    },
    queryJoinOrgApplyDetails() {
      let self = this;
      queryJoinOrgApplyDetail({ sign: "wx" }).then((res) => {
        if (res.code == 200) {
          self.codeMap = res.data.codeMap;
          self.memberApply = res.data.memberApply;
          // person
          for (let key in self.person) {
            for (let i in self.memberApply) {
              if (key === i && self.memberApply[i]) {
                self.$set(self.person, key, self.memberApply[i]);
              }
            }
          }
        } else {
          self.$toast(res.msg);
        }
      });
    },

    onBirthDate(date) {
      this.$set(this.personApplyInfo, "birthDate", this.timeFormat(date));
      this.MemTypeShow = false;
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "年" + month + "月" + day + "日";
    },
    onArea(values) {
      let area = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.$set(this.basic, "province", area);
      this.showArea = false;
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      setTimeout(() => {
        file.status = "done";
        file.message = "上传成功";
      }, 1000);
    },

    // 团体理事单位
    onGrade(value) {
      this.$set(this.orgApplyInfo, "grade", value);
      this.showGrade = false;
    },
    // 我已阅读并同意
    onLookAndAgree() {
      console.log(this.checked);
    },

    onSubmit() {
      let self = this;
      self.$store.commit("showLoading");
      self.customForms.map((item) => {
        for (let key in self.person) {
          if (item.vModel == key) {
            item.defaultValue = self.person[key];
            self.$set(self.person.code, item.vModel, self.person[key]);
          }
        }
      });
      centerApplyJoinOrg(self.person).then(res => {
        self.$toast(res.msg);
        self.$store.commit("hideLoading");
        self.$router.push('/members');
        
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#app .content {
  padding-left: 0;
  padding-right: 0;
}
.members-apply-table {
  .members-apply-title {
    text-align: center;
    font-size: 16px;
  }
}
.memberInfos {
  padding: 16px;
  li {
    display: flex;
    padding: 10px;
    margin-bottom: 16px;
    border-radius: 6px;
    color: #343c50;
    box-shadow: -3px 3px 6px #edeff0;
    .m-name {
      flex-shrink: 0;
      // width: 100px;
    }
    .m-value {
      padding-left: 16px;
      flex: 1;
      color: #888;
    }
  }
}
.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-state {
  color: #1a1613;
  font: 16px sans-serif;
  .van-icon {
    padding-right: 10px;
  }
}
</style>