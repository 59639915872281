<template>
  <div class="login">
    <div class="content">
      <div class="members-apply-table">
        <template v-if="userType == 1">
          <h2 class="member-title">个人会员信息展示</h2>
          <ul class="member-info">
            <li>
              <span class="m-name">会员类型</span>
              <span class="m-value">{{ members.memberName }}</span>
            </li>
            <li>
              <span class="m-name">会员编号</span>
              <span class="m-value">{{
                `${members.memberPerfix}${members.memberCodeType}${members.memberCode}`
              }}</span>
            </li>
            <li>
              <span class="m-name">所属单位</span>
              <span class="m-value">{{ members.sourceOrgName }}</span>
            </li>
            <li>
              <span class="m-name">入会时间</span>
              <span class="m-value">{{ members.initiationTime }}</span>
            </li>
            <li>
              <span class="m-name">是否缴费</span>
              <span class="m-value">{{
                members.ifFee == 0 ? "已缴费" : "未缴费"
              }}</span>
            </li>
            <li>
              <span class="m-name">会员有效期</span>
              <span class="m-value">{{ members.dueTime }}</span>
            </li>
            <li v-if="members.ifFee == 0">
              <van-cell
                title="我的会员证"
                value="查看"
                @click="onChangeTitle('我的会员证')"
                is-link
                to="members-card"
              />
            </li>
            <li v-else>
              <van-cell
                title="我的订单"
                @click="onChangeTitle('订单信息')"
                value="去支付"
                is-link
                :to="`/members-pay/${members.orderId}`"
              />
              <!-- icon="idcard"  goNewsDeatil(item) {
      this.$router.push(`/members-pay/${item.id}`);
    },-->
            </li>
          </ul>
        </template>
        <template v-else>
          <h2 class="member-title">协会团体会员信息展示</h2>
          <ul class="member-info">
            <li>
              <span class="m-name">会员类型</span>
              <span class="m-value">{{ members.memberName }}</span>
            </li>
            <li>
              <span class="m-name">会员编号</span>
              <span class="m-value">{{
                `${members.memberPerfix}${members.memberCodeType}${members.memberCode}`
              }}</span>
            </li>
            <li>
              <span class="m-name">所属单位</span>
              <span class="m-value">{{ members.sourceOrgName }}</span>
            </li>
            <li>
              <span class="m-name">入会时间</span>
              <span class="m-value">{{ members.initiationTime }}</span>
            </li>
            <li>
              <span class="m-name">是否缴费</span>
              <span class="m-value">{{
                members.ifFee == 0 ? "已缴费" : "未缴费"
              }}</span>
            </li>
            <li>
              <span class="m-name">会员有效期</span>
              <span class="m-value">{{ members.dueTime }}</span>
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { queryOrgMemberInfo } from "@/api/user";
export default {
  name: "myuser",
  data() {
    return {
      user: {},
      userType: 0,
      members: {},
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  mounted() {
    this.userType = this.userinfo.userType;
    this.queryOrgMemberInfos();
  },
  methods: {
    queryOrgMemberInfos() {
      let self = this;
      self.$store.commit("showLoading");
      queryOrgMemberInfo({ sign: "wx" }).then((res) => {
        if (res.code == 200) {
          self.members = res.data;
        } else {
          self.$toast(res.msg);
        }
        self.$store.commit("hideLoading");
      });
    },
    onChangeTitle(item) {
      let _title = item instanceof Object ? item.name : item;
      this.$store.dispatch("saveHeadTitle", _title);
    },
  },
};
</script>
<style lang="scss" scoped>
#app .content {
  padding-left: 0;
  padding-right: 0;
}
.member-title {
  text-align: center;
}
.member-info {
  padding: 16px;
  li {
    display: flex;
    padding: 10px;
    margin-bottom: 16px;
    border-radius: 6px;
    color: #343c50;
    box-shadow: -3px 3px 6px #edeff0;
    .m-name {
      flex-shrink: 0;
      width: 100px;
    }
    .m-value {
      flex: 1;
      color: #888;
    }
  }
}
</style>