<template>
  <van-nav-bar
    class="nav-bar"
    :title="titles"
    left-arrow
    @click-left="goback"
    fixed
  />
  <!-- van-nav-bar title="标题" left-text="返回" left-arrow>
    <template #right>
      <van-icon name="search" size="18" />
    </template>
  </van-nav-bar> -->
</template>
<script>
export default {
  name: "nav-bar",
  data() {
    return {
      title: "home",
    };
  },
  computed: {
    titles() {
      return this.$store.state.headtitle;
    },
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  /deep/ .van-icon {
    color: #fe051b;
    border-radius: 100%;
    background: #fff;
    box-shadow: -6px 6px 16px #e3e4e6;
    padding: 10px;
  }
}
</style>