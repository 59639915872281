<template>
  <div class="indexbg">
    <nav-header></nav-header>
    <div class="index">
      <van-search
        v-model="searvalue"
        class="search-bar"
        show-action
        shape="round"
        background="#fff"
        @search="onSearch"
        placeholder="请输入搜索关键词"
      >
        <template #action>
          <van-button class="btn-sear" @click="onSearch" round> 搜 </van-button>
        </template>
      </van-search>

      <van-swipe class="banner" :autoplay="5000" :loop="false">
        <van-swipe-item v-for="(ban, index) in banner" :key="index">
          <van-image fit="cover" lazy-load :src="loadUrl(ban.ckImg)" />
          <span class="ban-title">{{ ban.title }}</span>
        </van-swipe-item>
      </van-swipe>

      <section class="service">
        <ul>
          <li v-for="(serve, index) in service" :key="index">
            <router-link :to="serve.path">
              <van-icon :name="serve.icon" />
              <span>{{ serve.name }}</span>
            </router-link>
          </li>
        </ul>
      </section>

      <section class="news">
        <van-cell
          title="政策法规"
          value="更多"
          is-link
          icon="award-o"
          @click="goNewsList('policy', '政策法规')"
        />
        <ul class="news-list">
          <li v-for="(nItem, index) in policyList.slice(0, 3)" :key="index">
            <span class="news-name" @click="goNewsDeatil(nItem, '政策法规')">{{
              nItem.noticeTitle
            }}</span>
            <span class="news-time">{{ nItem.createTime.slice(0, 10)  }}</span>
          </li>
        </ul>
      </section>

      <section class="news">
        <van-cell
          title="新闻动态"
          value="更多"
          is-link
          @click="goNewsList('news', '新闻动态')"
          icon="hot-o"
        />
        <ul class="news-list">
          <li v-for="(nItem, index) in newsList.slice(0, 3)" :key="index">
            <span class="news-name" @click="goNewsDeatil(nItem, '新闻动态')">{{
              nItem.noticeTitle
            }}</span>
            <span class="news-time">{{ nItem.createTime.slice(0, 10)  }}</span>
          </li>
        </ul>
      </section>

      <section class="news">
        <van-cell
          title="通知公告"
          value="更多"
          is-link
          @click="goNewsList('information', '通知公告')"
          icon="volume-o"
        />
        <ul class="news-list">
          <li v-for="(nItem, index) in noticeList.slice(0, 3)" :key="index">
            <span class="news-name" @click="goNewsDeatil(nItem, '通知公告')">{{
              nItem.noticeTitle
            }}</span>
            <span class="news-time">{{ nItem.createTime.slice(0, 10) }}</span>
          </li>
        </ul>
      </section>

      <!-- <section class="news">
        <van-cell
          title="最新赛事"
          value="更多"
          icon="smile-o"
          is-link
          to="event"
        />
        <div class="event-box">
          <ul class="event">
            <li v-for="(event, index) in events.slice(0, 3)" :key="index">
              <van-image fit="cover" lazy-load :src="loadUrl(event.imgUrl)" />
              <div class="event-card">
                <h4>{{ event.competitionName }}</h4>
                <span class="time">
                  <van-icon name="underway-o" /> 申请：{{ sundoDate(event.competitionApplyBeginDate) }} 至  {{ sundoDate(event.competitionApplyEndDate) }}</span
                >
                 <span class="time">
                  <van-icon name="underway" />  比赛：{{ sundoDate(event.competitionMatchBeginDate) }} 至 {{ sundoDate(event.competitionMatchEndDate) }}</span
                >
                 <span class="site">
                  <van-icon name="map-marked" /> {{ event.province }}{{ event.city }}{{ event.address }}</span
                >
                <span class="gold">
                  <van-icon name="gold-coin" /> {{ event.applyPay }} 元</span
                >
              </div>
            </li>
          </ul>
        </div>
      </section> -->

      <section class="Copyright">
        Copyright © GUANGDONG WUSHU ASSOCIATION.<br /> 广东省武术协会版权所有 <br /> 技术支持：广州松冬体育信息咨询有限公司  
      </section>
    </div>
  </div>
</template>

<script>
import { getHome } from "@/api/index";
import NavHeader from "@/components/NavHeader";
export default {
  name: "index",
  components: {
    NavHeader,
  },
  data() {
    return {
      sign: "wx",
      searvalue: "",
      banner: [],
      service: [
        {
          name: "会员服务",
          path: "/members",
          icon: "vip-card-o",
        },
        {
          name: "段位查询",
          path: "/members",
          icon: "search",
        },
        {
          name: "赛事服务",
          path: "/event",
          icon: "smile-o",
        },
        {
          name: "教学服务",
          path: "/teaching",
          icon: "video-o",
        },
        {
          name: "多媒体区",
          path: "/teaching",
          icon: "tv-o",
        },
      ],
      newsList: [],
      noticeList: [],
      policyList: [],
      events: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const self = this;
      let sign = self.sign;
      self.$store.commit("showLoading");

      getHome({ sign }).then((res) => {
        if (res.code == 200) {
          self.banner = res.data.bannerList;
          self.newsList = res.data.newsList;
          self.noticeList = res.data.noticeList;
          self.policyList = res.data.policyList;
          self.events = res.data.competitionList;
          self.$store.commit("hideLoading");
        }
      });
    },
    goNewsDeatil(nItem, name) {
      this.$store.dispatch("saveHeadTitle", name);
      this.$store.dispatch("saveFootActive", name);
      this.$router.push(`/news-detail/${nItem.id}`);
    },
    goNewsList(path, name) {
      this.$store.dispatch("saveHeadTitle", name);
      this.$store.dispatch("saveFootActive", name);
      this.$router.push(path);
    },
    onSearch() {},
  },
};
</script>

<style lang="scss" scoped>
.indexbg {
  padding-top: 60px;
  background: #fe051b;
}
.index {
  padding-top: 30px;
  border-radius: 30px 30px 0 0;
  background: linear-gradient(to bottom, #f6f6f6, #fbfffa);
}
</style>