<template>
  <div class="content">
    <div class="user-head">
      <van-image
        round
        width="6rem"
        height="6rem"
        fit="cover"
        :src="loadUrl(user.avatar)"
      >
        <template v-slot:error>
          <van-icon name="contact" />
        </template>
      </van-image>

      <span class="name"> {{ user.userName }}</span>
      <span class="tel">{{ user.phonenumber }}</span>
    </div>

    <van-cell-group class="user-item">
      <van-cell
        v-if="ifVip != 3"
        title="会员申请"
        value=""
        @click="onChangeTitle('会员申请')"
        is-link
        to="members-apply"
        icon="user-o"
      />
      <van-cell
        v-else
        title="会员信息"
        value=""
        @click="onChangeTitle('会员信息')"
        is-link
        to="members-detail"
        icon="description"
      />
    </van-cell-group>
  </div>
</template>
<script>
import { checkUserIsOrgMember } from "@/api/user";
export default {
  name: "members",
  data() {
    return {
      user: {},
      ifVip: 3,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  mounted() {
    let self = this;
    self.$store.commit("showLoading");
    checkUserIsOrgMember({ sign: "wx" }).then((res) => {
      if (res.code == 200) {
        self.ifVip = res.data.isOrgMember;
      } else {
        self.$toast(res.msg);
      }
      self.$store.commit("hideLoading");
    });

    self.user = self.userinfo.userInfo;
    if (self.userinfo.userType == 1) {
      self.user = self.userinfo.userInfo;
    } else {
      self.user = self.userinfo.userInfo;
      self.user.avatar = self.userinfo.orgInfo.img;
      self.user.updateTime = self.userinfo.orgInfo.updateTime;
    }

    if (!self.user.updateTime) {
      self.$toast("请先完善用户基本信息！");
      self.$router.push("/myuser");
    }
  },
  methods: {
    onChangeTitle(item) {
      let _title = item instanceof Object ? item.name : item;
      this.$store.dispatch("saveHeadTitle", _title);
    },
  },
};
</script>
<style lang="scss" scoped>
.user-head {
  text-align: center;
  padding: 16px 0;
  .van-image {
    border-radius: 50%;
    border: solid 8px #fff;
    box-shadow: -6px 6px 20px #eee;
  }
  /deep/ .van-icon {
    font-size: 5rem;
  }
  .name {
    padding: 10px 0;
    display: block;
    color: #1a1613;
    font-size: 20px;
  }
  .tel {
    display: block;
    color: #999;
    font-size: 14px;
  }
}

.user-item {
  margin-top: 16px;
  &::after {
    border: 0;
  }
  .van-cell {
    margin: 10px 0;
    background: #fff;

    border-radius: 22px;
    box-shadow: -4px 6px 16px #f1f2f4;

    &::after {
      border: 0;
    }
  }
}
</style>