<template>
  <div class="login">
    <div class="content">
      <div class="user-head">
        <van-image
          round
          width="6rem"
          height="6rem"
          fit="cover"
          :src="loadUrl(userInfo.avatar)"
        >
          <template v-slot:error>
            <van-icon name="contact" />
          </template>
        </van-image>

        <span class="name"> {{ userInfo.userName }}</span>
        <span class="tel">{{ userInfo.phonenumber }}</span>
      </div>

      <!-- 个人 -->
      <template v-if="userinfo.userType == 1">
        <van-tabs class="tab-bar" :swipeable="true" :ellipsis="false">
          <van-tab title="基本信息">
            <van-form @submit="onSubmit(1)" class="base-form">
              <van-field
                v-model="basic.nickName"
                name="用户昵称"
                placeholder="请输入用户昵称"
                clearable
                label="用户昵称"
                :rules="[{ required: true, message: '请填写用户昵称' }]"
              />
              <van-field
                v-model="basic.userName"
                name="真实姓名"
                placeholder="请输入真实姓名"
                clearable
                label="真实姓名"
                :rules="[{ required: true, message: '请填写真实姓名' }]"
              />
              <van-field name="radio" label="单选框">
                <template #input>
                  <van-radio-group v-model="basic.sex" direction="horizontal">
                    <van-radio name="1">男</van-radio>
                    <van-radio name="2">女</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                v-model="basic.phonenumber"
                name="手机号码"
                placeholder="请输入手机号码"
                clearable
                label="手机号码"
                :rules="[{ required: true, message: '请填写手机号码' }]"
              />
              <van-field
                v-model="basic.email"
                clearable
                name="电子邮箱"
                placeholder="请输入电子邮箱"
                label="电子邮箱"
              />

              <!-- <van-field
                readonly
                clickable
                name="picker"
                :value="basic.identityType"
                label="证件类型"
                placeholder="点击证件类型"
                right-icon="arrow-down"
                @click="showPicker = true"
              />
              <van-popup v-model="showPicker" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="papersColumns"
                  @confirm="onPapersType"
                  @cancel="showPicker = false"
                />
              </van-popup> -->

              <van-field
                v-model="basic.identityCode"
                clearable
                name="证件号码"
                placeholder="请输入证件号码"
                label="证件号码"
                :rules="[{ required: true, message: '请填写证件号码' }]"
              />

              <van-field
                readonly
                clickable
                name="datetime-picker"
                :value="basic.birthday"
                label="出生日期"
                right-icon="birthday-cake-o"
                placeholder="点击出生日期"
                @click="showCalendar = true"
              />

              <van-popup v-model="showCalendar" position="bottom">
                <van-datetime-picker
                  v-model="currentDate"
                  type="date"
                  title="选择出生日期"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onBirthDate"
                />
              </van-popup>

              <van-field
                readonly
                clickable
                name="area"
                :value="basic.province"
                right-icon="location-o"
                label="所在地"
                placeholder="点击选择省市区"
                @click="showArea = true"
              />
              <van-popup v-model="showArea" position="bottom">
                <van-area
                  :area-list="areaList"
                  @confirm="onArea"
                  @cancel="showArea = false"
                />
              </van-popup>

              <van-field
                v-model="basic.area"
                name="详细地址"
                placeholder="请输入详细地址"
                label="详细地址"
              />

              <van-field
                name="uploader"
                label="证件照"
                @click="getType('basic')"
              >
                <template #input>
                  <van-uploader
                    v-model="basic.avatars"
                    multiple
                    accept="image/*"
                    :after-read="afterRead"
                    :max-count="1"
                  />
                </template>
              </van-field>

              <van-button round block class="btn-true" native-type="submit"
                >保存</van-button
              >
            </van-form>
          </van-tab>
          <van-tab title="段位信息">
            <van-form @submit="onSubmitCert" class="base-form">
              <van-field
                v-model="dan.item"
                clearable
                name="考试项目"
                placeholder="请输入考试项目"
                label="考试项目"
                :rules="[{ required: true, message: '请填写考试项目' }]"
              />
              <van-field
                readonly
                clickable
                right-icon="gem-o"
                name="level"
                :value="dan.level"
                label="等级"
                placeholder="点击您的级别"
                @click="danShowGrade = true"
              />
              <van-popup v-model="danShowGrade" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="danGradeColumns"
                  @confirm="onDanGrade"
                  @cancel="danShowGrade = false"
                />
              </van-popup>
              <van-field
                v-model="dan.certCode"
                name="段位编号"
                placeholder="请输入段位编号"
                label="段位编号"
                :rules="[{ required: true, message: '请填写段位编号' }]"
              />
              <van-field
                v-model="dan.certSource"
                name="审批单位"
                placeholder="请输入审批单位"
                label="审批单位"
                :rules="[{ required: true, message: '请填写审批单位' }]"
              />
              <van-field
                readonly
                clickable
                right-icon="calendar-o"
                name="dan-certificateDate"
                :value="dan.certTime"
                label="审批日期"
                placeholder="点击审批日期"
                @click="showDan = true"
              />

              <van-popup v-model="showDan" position="bottom">
                <van-datetime-picker
                  v-model="currentDate"
                  type="date"
                  title="选择审批日期"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onDanCertificateDate"
                />
              </van-popup>
              <van-field
                v-model="dan.certResume"
                name="履历"
                placeholder="请输履历"
                label="履历"
                type="textarea"
              />
              <van-field
                name="uploader"
                label="证书上传"
                @click="getType('dan')"
              >
                <template #input>
                  <van-uploader
                    v-model="dan.certImgs"
                    multiple
                    :after-read="afterRead"
                    :max-count="1"
                  />
                </template>
              </van-field>
              <van-button round block class="btn-true" native-type="submit"
                >保存</van-button
              >
            </van-form>
          </van-tab>
          <van-tab title="执教信息">
            <van-form @submit="onSubmitCert" class="base-form">
              <van-field
                v-model="coach.item"
                clearable
                name="项目名称"
                placeholder="请输入项目名称"
                label="项目名称"
                :rules="[{ required: true, message: '请填写项目名称' }]"
              />
              <van-field
                readonly
                clickable
                right-icon="gem-o"
                name="level"
                :value="coach.level"
                label="等级"
                placeholder="点击您的级别"
                @click="coachShowGrade = true"
              />
              <van-popup v-model="coachShowGrade" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="coachGradeColumns"
                  @confirm="onCoachGrade"
                  @cancel="coachShowGrade = false"
                />
              </van-popup>
              <van-field
                v-model="coach.certCode"
                name="证书编号"
                placeholder="请输入证书编号"
                label="证书编号"
                :rules="[{ required: true, message: '请填写证书编号' }]"
              />
              <van-field
                v-model="coach.certSource"
                name="核发机构"
                placeholder="请输入核发机构"
                label="核发机构"
                :rules="[{ required: true, message: '请填写核发机构' }]"
              />
              <van-field
                readonly
                clickable
                right-icon="calendar-o"
                name="coach-certificateDate"
                :value="coach.certTime"
                label="证书日期"
                placeholder="点击证书日期"
                @click="showCCD = true"
              />

              <van-popup v-model="showCCD" position="bottom">
                <van-datetime-picker
                  v-model="currentDate"
                  type="date"
                  title="选择证书日期"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onCoachCertificateDate"
                />
              </van-popup>
              <van-field
                v-model="coach.certResume"
                name="履历"
                placeholder="请输履历"
                label="履历"
                type="textarea"
              />
              <van-field
                name="uploader"
                label="证书上传"
                @click="getType('coach')"
              >
                <template #input>
                  <van-uploader
                    v-model="coach.certImgs"
                    multiple
                    :after-read="afterRead"
                    :max-count="1"
                  />
                </template>
              </van-field>
              <van-button round block class="btn-true" native-type="submit"
                >保存</van-button
              >
            </van-form>
          </van-tab>
          <van-tab title="执裁信息">
            <van-form @submit="onSubmitCert" class="base-form">
              <van-field
                v-model="referee.item"
                clearable
                name="项目名称"
                placeholder="请输入项目名称"
                label="项目名称"
                :rules="[{ required: true, message: '请填写项目名称' }]"
              />
              <van-field
                readonly
                clickable
                right-icon="gem-o"
                name="level"
                :value="referee.level"
                label="等级"
                placeholder="点击您的级别"
                @click="refereeShowGrade = true"
              />
              <van-popup v-model="refereeShowGrade" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="refereeGradeColumns"
                  @confirm="onRefereeGrade"
                  @cancel="refereeShowGrade = false"
                />
              </van-popup>
              <van-field
                v-model="referee.certCode"
                name="证书编号"
                placeholder="请输入证书编号"
                label="证书编号"
                :rules="[{ required: true, message: '请填写证书编号' }]"
              />
              <van-field
                v-model="referee.certSource"
                name="核发机构"
                placeholder="请输入核发机构"
                label="核发机构"
                :rules="[{ required: true, message: '请填写核发机构' }]"
              />
              <van-field
                readonly
                clickable
                right-icon="calendar-o"
                name="referee-certificateDate"
                :value="referee.certTime"
                label="证书日期"
                placeholder="点击证书日期"
                @click="showRCD = true"
              />

              <van-popup v-model="showRCD" position="bottom">
                <van-datetime-picker
                  v-model="currentDate"
                  type="date"
                  title="选择证书日期"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onRefereeCertificateDate"
                />
              </van-popup>
              <van-field
                v-model="referee.certResume"
                name="履历"
                placeholder="请输履历"
                label="履历"
                type="textarea"
              />
              <van-field
                name="uploader"
                label="证书上传"
                @click="getType('referee')"
              >
                <template #input>
                  <van-uploader
                    v-model="referee.certImgs"
                    multiple
                    :after-read="afterRead"
                    :max-count="1"
                  />
                </template>
              </van-field>
              <van-button round block class="btn-true" native-type="submit"
                >保存</van-button
              >
            </van-form>
          </van-tab>
          
          <van-tab title="运动员等级信息">
            <van-form @submit="onSubmitCert" class="base-form">
              <van-field
                v-model="player.item"
                clearable
                name="考试项目"
                placeholder="请输入考试项目"
                label="考试项目"
                :rules="[{ required: true, message: '请填写考试项目' }]"
              />

              <van-field
                readonly
                clickable
                right-icon="gem-o"
                name="playerLv"
                :value="player.level"
                label="运动员等级"
                placeholder="点击您的级别"
                @click="athletesShowGrade = true"
              />
              <van-popup v-model="athletesShowGrade" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="athletesGradeColumns"
                  @confirm="onAthletesGrade"
                  @cancel="athletesShowGrade = false"
                />
              </van-popup>

              <!-- <van-field
                readonly
                clickable
                right-icon="gem-o"
                name="level"
                :value="dan.level"
                label="等级"
                placeholder="点击您的级别"
                @click="danShowGrade = true"
              />
              <van-popup v-model="danShowGrade" position="bottom">
                <van-picker
                  show-toolbar
                  :columns="danGradeColumns"
                  @confirm="onDanGrade"
                  @cancel="danShowGrade = false"
                />
              </van-popup> -->

              <van-field
                v-model="player.certCode"
                name="段位编号"
                placeholder="请输入段位编号"
                label="段位编号"
                :rules="[{ required: true, message: '请填写段位编号' }]"
              />
              <van-field
                v-model="player.certSource"
                name="审批单位"
                placeholder="请输入审批单位"
                label="审批单位"
                :rules="[{ required: true, message: '请填写审批单位' }]"
              />
              <van-field
                readonly
                clickable
                right-icon="calendar-o"
                name="dan-certificateDate"
                :value="player.certTime"
                label="审批日期"
                placeholder="点击审批日期"
                @click="showPlayer = true"
              />

              <van-popup v-model="showPlayer" position="bottom">
                <van-datetime-picker
                  v-model="currentDate"
                  type="date"
                  title="选择审批日期"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onPlayer"
                />
              </van-popup>
              <van-field
                v-model="player.certResume"
                name="履历"
                placeholder="请输履历"
                label="履历"
                type="textarea"
              />
              <van-field
                name="uploader"
                label="证书上传"
                @click="getType('player')"
              >
                <template #input>
                  <van-uploader
                    v-model="player.certImgs"
                    multiple
                    :after-read="afterRead"
                    :max-count="1"
                  />
                </template>
              </van-field>
              <van-button round block class="btn-true" native-type="submit"
                >保存</van-button
              >
            </van-form>
          </van-tab>

          <!-- <div class="item-bar">
          <van-checkbox v-model="checkbox" checked-color="#fe051b"
            >我已阅读并同意</van-checkbox
          ><span class="to-link">《相关服务条款和隐私政策》</span>
        </div>
        <div class="item-bar">
          <span class="span-link"
            >已经有账号？<router-link to="/login" class="Black"
              >登录</router-link
            ></span
          >
        </div> -->
        </van-tabs>
      </template>

      <!-- 单位 -->
      <template v-else>
        <van-form
          @submit="onSubmit(2)"
          class="base-form"
          style="padding-top: 30px"
        >
          <van-field
            v-model="unitBasic.name"
            name="单位名称"
            placeholder="请输入单位名称"
            clearable
            label="单位名称"
            :rules="[{ required: true, message: '请填写单位名称' }]"
          />
          <van-field
            v-model="unitBasic.principal"
            name="联系人"
            placeholder="请输入联系人"
            clearable
            label="联系人"
            :rules="[{ required: true, message: '请填写联系人' }]"
          />

          <van-field
            v-model="unitBasic.phone"
            name="联系电话"
            placeholder="请输入联系电话"
            clearable
            label="联系电话"
            :rules="[{ required: true, message: '请填写联系电话' }]"
          />
          <van-field
            v-model="unitBasic.creditCode"
            clearable
            name="统一社会信用代码"
            placeholder="请输入统一社会信用代码"
            label="统一社会信用代码"
          />
          <van-field
            v-model="unitBasic.email"
            clearable
            name="电子邮箱"
            placeholder="请输入电子邮箱"
            label="电子邮箱"
          />

          <van-field
            readonly
            clickable
            name="area"
            :value="unitBasic.province"
            right-icon="location-o"
            label="所在地"
            placeholder="点击选择省市区"
            @click="showArea = true"
          />
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onArea"
              @cancel="showArea = false"
            />
          </van-popup>

          <van-field
            v-model="unitBasic.area"
            name="详细地址"
            placeholder="请输入详细地址"
            label="详细地址"
          />

          <van-field
            v-model="unitBasic.introduction"
            clearable
            name="介绍"
            type="textarea"
            placeholder="请输入介绍"
            label="介绍"
            rows="2"
            autosize
            maxlength="600"
            :rules="[{ required: true, message: '请填写介绍' }]"
          />

          <van-field
            v-model="unitBasic.constitution"
            clearable
            name="章程"
            type="textarea"
            rows="2"
            autosize
            maxlength="500"
            placeholder="请输入章程"
            label="章程"
            :rules="[{ required: true, message: '请填写章程' }]"
          />

          <van-field
            name="uploader"
            label="营业执照上传"
            @click="getType('license')"
          >
            <template #input>
              <van-uploader
                v-model="unitBasic.licenses"
                multiple
                :after-read="afterRead"
                :max-count="1"
              />
            </template>
          </van-field>

          <van-field
            name="uploader"
            label="证件照"
            @click="getType('unitBasic')"
          >
            <template #input>
              <van-uploader
                v-model="unitBasic.avatars"
                multiple
                :after-read="afterRead"
                :max-count="1"
              />
            </template>
          </van-field>

          <van-button round block class="btn-true" native-type="submit"
            >保存</van-button
          >
        </van-form>
      </template>
    </div>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import { updUserInfo, uploadImage, addOrUpdUserCert } from "@/api/user";

export default {
  name: "myuser",
  data() {
    return {
      user: {},
      userInfo: {},
      cId: 0,
      cType: "",
      parameters: {},
      unitBasic: {
        name: "",
        principal: "",
        phone: "",
        email: "",
        creditCode: "",
        province: "",
        city: "",
        county: "",
        area: "",
        introduction: "",
        constitution: "",
        license: "",
        licenses: [],
        img: "",
        avatars: [],
      },
      basic: {
        playerLv: "",
        nickName: "",
        userName: "",
        sex: "",
        phonenumber: "",
        email: "",
        // identityType: "",
        identityCode: "",
        birthday: "",
        province: "",
        city: "",
        county: "",
        street: "",
        area: "",
        avatars: [],
        avatar: "",
      },
      coach: {
        type: 0,
        id: "",
        item: "",
        level: "",
        certCode: "",
        certSource: "",
        certTime: "",
        certResume: "",
        certImg: "",
        certImgs: [],
      },
      referee: {
        type: 1,
        id: "",
        item: "",
        level: "",
        certCode: "",
        certSource: "",
        certTime: "",
        certResume: "",
        certImg: "",
        certImgs: [],
      },
      dan: {
        type: 2,
        id: "",
        item: "",
        level: "",
        certCode: "",
        certSource: "",
        certTime: "",
        certResume: "",
        certImg: "",
        certImgs: [],
      },
      player: {
        type: 3,
        id: "",
        item: "",
        playerLv: "",
        level: "",
        certCode: "",
        certSource: "",
        certTime: "",
        certResume: "",
        certImg: "",
        certImgs: [],
      },

      papersColumns: ["身份证"],
      showPicker: false,
      showCalendar: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      showArea: false,
      areaList, // 数据格式见 Area 组件文档

      showPlayer: false,

      athletesShowGrade: false,
      athletesGradeColumns: [
        "国际级运动健将",
        "运动健将",
        "一级",
        "二级",
        "三级",
      ],

      coachShowGrade: false,
      coachGradeColumns: ["国家级", "高级", "一级", "二级", "三级"],
      showCCD: false,

      refereeShowGrade: false,
      refereeGradeColumns: ["国际级", "国家级", "一级", "二级", "三级"],
      showRCD: false,

      danShowGrade: false,
      danGradeColumns: ["一段", "二段", "三段"],
      showDan: false,

      checkbox: true,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.userinfo;
    },
  },
  mounted() {
    let self = this;
    self.userInfo = self.userinfo.userInfo;
    if (self.userinfo.userType == 1) {
      if (!self.userInfo.updateTime) {
        self.basic.userName = self.userInfo.userName;
        self.basic.phonenumber = self.userInfo.phonenumber;
        self.basic.identityCode = self.userInfo.identityCode;
    
        self.basic.birthday = self.IdCard(
          self.userInfo.identityCode, 1
        );
         self.basic.sex = self.IdCard(
          self.userInfo.sex,2
        );
      } else {
        self.basic = self.userInfo;
        self.$set(self.basic, "avatars", [
          { url: self.loadUrl(self.basic.avatar) },
        ]);

        if (self.userinfo.coachInfo && self.userinfo.coachInfo.length > 0) {
          self.coach = self.userinfo.coachInfo[0];
          self.$set(self.coach, "certImgs", [
            { url: self.loadUrl(self.coach.certImg) },
          ]);
          console.log("coachInfo", self.userinfo.coachInfo);
        }
        if (self.userinfo.refereeInfo && self.userinfo.refereeInfo.length > 0) {
          self.referee = self.userinfo.refereeInfo[0];
          self.$set(self.referee, "certImgs", [
            { url: self.loadUrl(self.referee.certImg) },
          ]);
          console.log("refereeInfo", self.userinfo.refereeInfo);
        }
        if (self.userinfo.rankInfo && self.userinfo.rankInfo.length > 0) {
          self.dan = self.userinfo.rankInfo[0];
          self.$set(self.dan, "certImgs", [
            { url: self.loadUrl(self.dan.certImg) },
          ]);

          console.log("rankInfo", self.userinfo.rankInfo);
        }
        if (self.userinfo.playerInfo && self.userinfo.playerInfo.length > 0) {
          self.player = self.userinfo.playerInfo[0];
          self.$set(self.player, "certImgs", [
            { url: self.loadUrl(self.player.certImg) },
          ]);
          console.log("playerInfo", self.userinfo.playerInfo);
        }
      }
    } else {
      let orgInfo = self.userinfo.orgInfo;
      if (!orgInfo.updateTime) {
        self.unitBasic.name = orgInfo.name;
        self.unitBasic.phone = orgInfo.phone;
        self.unitBasic.creditCode = orgInfo.creditCode;
      } else {
        self.unitBasic = orgInfo;
        self.userInfo.avatar = self.unitBasic.img;
        self.$set(self.unitBasic, "avatars", [
          { url: self.loadUrl(self.unitBasic.img) },
        ]);
        self.$set(self.unitBasic, "licenses", [
          { url: self.loadUrl(self.unitBasic.license) },
        ]);
      }
    }

    console.log("user", this.userinfo);
  },
  methods: {
  
    getType(type) {
      this.cType = type;
    },
    onPapersType(value) {
      this.showPicker = false;
      this.$set(this.basic, "identityType", value);
    },
    onBirthDate(date) {
      this.$set(this.basic, "birthday", this.timeFormat(date));
      this.showCalendar = false;
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month =
        time.getMonth() <= 9
          ? "0" + (time.getMonth() + 1)
          : time.getMonth() + 1;
      let day = time.getDate() <= 9
          ? "0" + time.getDate() 
          : time.getDate()  ;
      let _time = `${year}-${month}-${day}`;
      return _time;
    },
    onArea(values) {
      let area = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");

      this.$set(this.unitBasic, "province", area);
      this.$set(this.basic, "province", area);
      this.showArea = false;
    },

    afterRead(file) {
      let self = this;
      file.status = "uploading";
      file.message = "上传中...";
      var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file); //接口需要传的参数

      uploadImage(formData).then((res) => {
        setTimeout(() => {
          let str = res.fileName;
          file.fileName = str;
          if (self.cType == "basic") {
            self.basic.avatar = str;
            self.basic.avatars[0] = { url: self.loadUrl(str) };
          } else if (self.cType == "coach") {
            self.coach.certImg = str;
            self.coach.certImgs[0] = { url: self.loadUrl(str) };
          } else if (self.cType == "referee") {
            self.referee.certImg = str;
            self.referee.certImgs[0] = { url: self.loadUrl(str) };
          } else if (self.cType == "dan") {
            self.dan.certImg = str;
            self.dan.certImgs[0] = { url: self.loadUrl(str) };
          } else if (self.cType == "player") {
            self.player.certImg = str;
            self.player.certImgs[0] = { url: self.loadUrl(str) };
          } else if (self.cType == "unitBasic") {
            self.unitBasic.img = str;
            self.unitBasic.avatars[0] = { url: self.loadUrl(str) };
          } else if (self.cType == "license") {
            self.unitBasic.license = str;
            self.unitBasic.licenses[0] = { url: self.loadUrl(str) };
          }

          file.status = "done";
          file.message = "上传成功";
        }, 1000);
      });
    },

    // 基本运动员等级
    onAthletesGrade(value) {
      this.$set(this.player, "level", value);
      this.athletesShowGrade = false;
    },
    onPlayer(date) {
      this.$set(this.player, "certTime", this.timeFormat(date));
      this.showPlayer = false;
    },
    // 执教
    onCoachGrade(value) {
      this.$set(this.coach, "level", value);
      this.coachShowGrade = false;
    },
    onCoachCertificateDate(date) {
      this.$set(this.coach, "certTime", this.timeFormat(date));
      this.showCCD = false;
    },
    // 执裁
    onRefereeGrade(value) {
      this.$set(this.referee, "level", value);
      this.refereeShowGrade = false;
    },
    onRefereeCertificateDate(date) {
      this.$set(this.referee, "certTime", this.timeFormat(date));
      this.showRCD = false;
    },
    // 段位
    onDanGrade(value) {
      this.$set(this.dan, "level", value);
      this.danShowGrade = false;
    },
    onDanCertificateDate(date) {
      this.$set(this.dan, "certTime", this.timeFormat(date));
      this.showDan = false;
    },
    onSubmit(usertype) {
      let self = this;
      let parms = usertype == 1 ? self.basic : self.unitBasic;
      updUserInfo(parms).then((res) => {
        if (res.code == 200) {
          self.$toast(res.msg);
          self.$router.push("/user");
        } else {
          self.$toast(res.msg);
        }
      });
    },
    onSubmitCert() {
      let self = this;
      let parms =
        self.cType == "coach"
          ? self.coach
          : self.cType == "referee"
          ? self.referee
          : self.cType == "dan"
          ? self.dan
          : self.player;
      addOrUpdUserCert(parms).then((res) => {
        if (res.code == 200) {
          self.$toast(res.msg);
          self.$router.push("/user");
        } else {
          self.$toast(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#app .content {
  padding-left: 0;
  padding-right: 0;
}
.user-head {
  text-align: center;
  padding: 16px 0;
  .van-image {
    border-radius: 50%;
    border: solid 8px #fff;
    box-shadow: -6px 6px 20px #eee;
  }
  /deep/ .van-icon {
    font-size: 5rem;
  }
  .name {
    padding: 10px 0;
    display: block;
    color: #1a1613;
    font-size: 20px;
  }
  .tel {
    display: block;
    color: #999;
    font-size: 14px;
  }
}
.base-form {
  .van-cell {
    margin: 0px 4% 16px;
    width: 92%;
    padding: 10px 20px;
    background: #fbfffa;
    border: solid 2px #fff;
    border-radius: 32px;
    box-shadow: -6px 6px 16px #eff0f5;
    &::after {
      border-width: 0;
    }
  }
}
.item-bar {
  position: relative;
  display: block;
  padding: 10px 16px;
  color: #999;
  font-size: 12px;
  .to-link {
    text-align: left;
    text-indent: 9em;
    margin-top: -20px;
    display: block;
  }
  .span-link {
    display: block;
    text-align: center;
  }
}
.btn-true {
  margin: 0px 3% 16px;
  width: 94%;
  background: #fe051b;
  border: solid #fe051b 4px;
  color: #fff;
  box-shadow: -6px 6px 20px #eee;
}
.btn-send {
  background: #fff;
  border: solid #f1f2f4 1px;
  color: #444;
  border-radius: 16px;
  box-shadow: -6px 6px 16px #f1f2f4;
}
.tab-bar {
  // width: 100%;
  /deep/ .van-tabs__wrap {
    padding: 10px 0 20px;
  }
  /deep/ .van-tabs__nav {
    border-radius: 22px;
    background: #f7f8fc;
    width: 92%;
    margin: 0 auto;
    padding-bottom: 0;
    box-shadow: -10px 6px 16px #f1f2f4;
    .van-tab {
      // background: #1a1613;
      padding: 15px;
      color: #444;
      border-radius: 22px;
      &.van-tab--active {
        background: #fff;
        color: #1a1613;
        border: solid 2px #f1f2f4;
        box-shadow: 0 0 6px #f1f2f4;
      }
    }

    .van-tabs__line {
      display: none;
    }
  }
}
</style>