<template>
  <van-pull-refresh class="content" v-model="refreshing" @refresh="onRefresh">
    <van-list
      class="news-list"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell
        v-for="(item, index) in list"
        :key="index"
        :title="item.noticeTitle"
        is-link
        :label="item.createTime"
        @click="goNewsDeatil(item)"
      />
    </van-list>
  </van-pull-refresh>
</template>
<script>
export default {
  name: "news-list",
  props: {
    resData: Array,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  // watch: {
  //   'resData': {
  //     handler(val, oldVal) {
  //       if (val !== oldVal) {
  //         console.log(val, oldVal);
  //       }
  //     },
  //   },
  // },
  mounted() {
     this.onLoad()
  },

  methods: {
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }
        for (let i = 0; i < 10; i++) {
          this.list = this.resData;
        }
        this.loading = false;
        if (this.list.length >= this.resData.length) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    goNewsDeatil(item) {
      this.$router.push(`/news-detail/${item.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
#app .content {
  padding-left: 0;
  padding-right: 0;

  .news-list {
    padding: 0 16px;
    .van-cell--clickable {
      margin: 16px 0;
      border-radius: 16px;
      box-shadow: -6px 6px 16px #edefee;
      color: #999;
    }
  }
}
</style>