<!-- Menu -->
<template>
  <ul class="menu">
    <MenuItem :key="index" v-for="(item, index) in Linkes">
      <span class="menu-title" @click="golink(item)" v-if="!item.children">
        <router-link :to="{ path: item.path }">
          <van-icon :name="item.icon" /> {{ item.name }}
        </router-link>
      </span>

      <template v-else>
        <span class="menu-stitle" @click="handleToggleShow(item)">
          <span> <van-icon :name="item.icon" /> {{ item.name }} </span>
          <van-icon v-if="item.isopen" name="arrow-down" />
          <van-icon v-else name="arrow" />
        </span>
        <transition name="van-slide-left">
          <Menu :Linkes="item.children" v-if="item.isopen"></Menu>
        </transition>
      </template>
    </MenuItem>
  </ul>
</template>

<script>
import MenuItem from "./MenuItem";

export default {
  name: "Menu",
  props: {
    Linkes: Array,
  },
  components: { MenuItem },
  data() {
    return {
      toggleShow: false, // toggle 状态
    };
  },
  methods: {
    handleToggleShow(item) {
      item.isopen = !item.isopen;
    },
    golink(item) {
      this.$store.dispatch("saveHeadTitle", item.name);
      this.$store.dispatch("saveFootActive", item.name);
    },
  },
};
</script>


<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.menu {
  .menu-link {
    padding: 6px;
    transition: all 0.5s;
    box-shadow: -3px 3px 6px #000;
    border-radius: 16px;
    margin: 16px;
    &:hover {
      background: #313131;
      transition: all 0.5s;
      .van-icon {
        color: #fff;
      }
      span {
       color: #fff;
      }
    }
    span {
      width: 100%;
      vertical-align: top;
      line-height: 30px;

      .van-icon {
        color: #000;
        font-size: 18px;
        vertical-align: bottom;
        border-radius: 16px;
        background: #363636;
        box-shadow: -3px 3px 6px #000;
        padding: 6px;
      }
      &.menu-stitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #929292;
        a {
          color: #929292;
        }
      }
      &.menu-title {
        font-size: 14px;
        a {
          color: #929292;
        }
      }
    }
  }
}
</style>
