
<template>
  <div class="card-bar">
    <h2>正面</h2>
    <div class="card-front">
      <img src="@/assets/wsf-bg.jpg" />
      <div class="card-code">
        <span v-if="!user.qrcode">暂无生成二维码</span>
        <img v-else :src="loadUrl(user.qrcode)" />
        {{ user.qrcode }}
      </div>
      <div class="card-content">
        <div class="card-img">
          <img :src="loadUrl(user.avatar)" />
        </div>

        <ul class="card-ul">
          <li>
            <span class="card-name">姓名</span>
            <span class="card-value">{{ user.userName }}</span>
          </li>
          <li>
            <span class="card-name">性别</span>
            <span class="card-value">{{ user.sex == 1 ? "男" : "女" }}</span>
          </li>
          <li v-if="user.position">
            <span class="card-name">协会职务</span>
            <span class="card-value">{{ user.position }} </span>
          </li>
          <li
            class="pt38"
            v-if="user.refereeInfo && user.refereeInfo.length != 0"
          >
            <span class="card-name">裁判员等级</span>
            <span class="card-value">{{ user.refereeInfo[0].level }}</span>
          </li>
          <li v-if="user.coachInfo && user.coachInfo.length != 0">
            <span class="card-name">教练员等级</span>
            <span class="card-value">{{ user.coachInfo[0].level }}</span>
          </li>
          <li v-if="user.playerLv && user.playerLv.length != 0">
            <span class="card-name">运动员等级</span>
            <span class="card-value">{{ user.playerLv }}</span>
          </li>
          <li class="pt20">
            <span class="card-name">会员编号 </span>
            <span class="card-value"
              >{{ user.memberCodeType }}{{ user.memberCode }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <h2>反面</h2>
    <div class="card-reverse">
      <img src="@/assets/wsr-bg.jpg" />
      <div class="card-text" v-if="user.rankInfo && user.rankInfo.length != 0">
        <ul>
          <li>
            <span class="card-name">姓名</span>
            <span class="card-value">{{ user.userName }}</span>
          </li>
          <li>
            <span class="card-name">段位等级</span>
            <span class="card-value">{{ user.rankInfo[0].level }}</span>
          </li>
          <li>
            <span class="card-name">考试项目</span>
            <span class="card-value">{{ user.rankInfo[0].item }}</span>
          </li>
          <li>
            <span class="card-name">段位编号</span>
            <span class="card-value">{{ user.rankInfo[0].certCode }}</span>
          </li>
          <li>
            <span class="card-name">批准日期</span>
            <span class="card-value">{{ user.rankInfo[0].certTime }}</span>
          </li>
          <li>
            <span class="card-name">审批单位</span>
            <span class="card-value">{{ user.rankInfo[0].certSource }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mobileCreateCredentials } from "@/api/user";
export default {
  name: "card-main",
  data() {
    return {
      user: {},
      screenWidth:
        document.body.clientWidth > 1046 ? 1046 : document.body.clientWidth,
    };
  },
  mounted() {
    let self = this;
    self.$store.commit("showLoading");
    //获取二维码
    mobileCreateCredentials({ sign: "wx" }).then((res) => {
      let qrcode = res.data.qrcode ? "/upload/" + res.data.qrcode : "";
      self.user = res.data;
      self.user.qrcode = qrcode;
      self.$store.commit("hideLoading");
    });
    // 初始化
    self.setRem();

    window.addEventListener(
      "resize",
      function () {
        return (() => {
          window.screenWidth =
            document.body.clientWidth > 1046 ? 1046 : document.body.clientWidth;
          self.screenWidth = window.screenWidth;
        })();
      },
      true
    );
  },
  watch: {
    screenWidth: {
      handler(newVal, oldVal) {
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if (!this.timer) {
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.screenWidth = newVal;
          this.timer = true;
          let that = this;
          setTimeout(function () {
            // 打印screenWidth变化的值
            // console.log(that.screenWidth);
            that.timer = false;
            that.setRem(); //执行自己的逻辑
          }, 100);
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.setRem, true);
     document.documentElement.style.fontSize = '16px'
  },
  methods: {
    // 设置 rem 函数
    setRem() {
      document.documentElement.style.fontSize = this.screenWidth / 52.3 + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
.card-bar {
  color: #fff;
  margin-bottom: 80px;
  margin-top: 80px;
  h2 {
    text-align: center;
    width: 100%;
    color: #333;
  }
  .card-front,
  .card-reverse {
    position: relative;
    max-width: 1046px;
    max-height: 673px;
    margin: 20px auto;
    img {
      max-width: 100%;
    }
  }
  .card-code {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 0.4rem;
    color: #999;
    overflow: hidden;
    span {
      display: block;
      margin: 0 auto;
    }
  }
  .card-content {
    position: absolute;
    top: 9.5rem;
    left: 6.2rem;
    display: flex;
    .card-img {
      display: flex;
      justify-items: center;
      align-items: center;
      width: 14.7rem;
      max-height: 20.7rem;
      border-radius: 0.4rem;
      overflow: hidden;
    }
    .card-ul {
      padding-top: 0.2rem;
      padding-left: 2.6rem;
      li {
        line-height: 1.4rem;
        padding-bottom: 1.15rem;
        font-size: 1.4rem;
        font-weight: 600;
        display: block;
        &.pt38 {
          padding-top: 1.8rem;
        }
        &.pt20 {
          padding-top: 1rem;
        }
        .card-name {
          display: inline-block;
          width: 11.1rem;
        }
        .card-value {
          display: inline-block;
        }
      }
    }
  }
  .card-text {
    position: absolute;
    top: 0;
    left: 0;
    ul {
      display: block;
      padding-top: 16.2rem;
      padding-left: 16.8rem;
      li {
        line-height: 1.4rem;
        padding-bottom: 1.16rem;
        font-size: 1.4rem;
        font-weight: 600;
        .card-name {
          display: inline-block;
          width: 11.1rem;
        }
        .card-value {
          display: inline-block;
        }
      }
    }
  }
}
</style>
