
const baseURL = process.env.VUE_APP_BASE_API
//全局根路径
//var basePath ="http://www.gzsundo.com";
let basePath = "http://sundo.gzsundo.com";
// let basePath ="http://10.100.215.16:8686";

export default {
	install(Vue) {
		//加载图片路径渲染图片 +"/upload",图片加载路径
		Vue.prototype.loadUrl = function loadUrl(url) {
			return basePath + url;
		}
		// 日期格式化（全）
		Vue.prototype.formatNumber = function formatNumber(n) {
			n = n.toString();
			return n[1] ? n : '0' + n
		}
		// 日期格式化（全）
		const formatNumber = function (n) {
			let date
			if (this.isString(dateObj)) {
				date = this.strToDate(dateObj)
			} else if (this.isDate(dateObj)) {
				date = dateObj
			}
			else {
				return ""
			}
			n = n.toString();
			return n[1] ? n : '0' + n
		}

		/**
	 * 时间戳和日期转换(年月日+时间)
	 * @param times 
	 */
		Vue.prototype.formatDateTime = function formatDateTime(times) {
			let now = new Date(times)
			let year = now.getFullYear();
			let month = now.getMonth() + 1;
			let date = now.getDate();
			let hour = now.getHours();
			let minute = now.getMinutes();
			let second = now.getSeconds();
			return year + "-" + formatNumber(month) + "-" + formatNumber(date) + " " + formatNumber(hour) + ":" + formatNumber(minute) + ":" + formatNumber(second);
		}

		/**
		 * 时间戳和日期转换（年月日）
		 * @param {*} times 
		 */
		Vue.prototype.formatDate = function formatDate(times) {
			let now = new Date(times)
			let year = now.getFullYear();
			let month = now.getMonth() + 1;
			let date = now.getDate();
			return year + "-" + formatNumber(month) + "-" + formatNumber(date);
		}

		// 日期格式化
		Vue.prototype.parseTime = function parseTime(time, pattern) {
			if (arguments.length === 0 || !time) {
				return null
			}
			const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
			let date
			if (typeof time === 'object') {
				date = time
			} else {
				if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
					time = parseInt(time)
				} else if (typeof time === 'string') {
					time = time.replace(new RegExp(/-/gm), '/');
				}
				if ((typeof time === 'number') && (time.toString().length === 10)) {
					time = time * 1000
				}
				date = new Date(time)
			}
			const formatObj = {
				y: date.getFullYear(),
				m: date.getMonth() + 1,
				d: date.getDate(),
				h: date.getHours(),
				i: date.getMinutes(),
				s: date.getSeconds(),
				a: date.getDay()
			}
			const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
				let value = formatObj[key]
				if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
				if (result.length > 0 && value < 10) {
					value = '0' + value
				}
				return value || 0
			})
			return time_str
		}

		/**
		 * 身份证获取出身（年月日）/ 年龄/性别
		 * @param {*} IdCard  
		 */
		Vue.prototype.IdCard = function IdCard(UUserCard, num) {
			if (num == 1) {
				//获取出生日期
				let birth = UUserCard.substring(6, 10) + "-" + UUserCard.substring(10, 12) + "-" + UUserCard.substring(12, 14);
				return birth;
			}
			if (num == 2) {
				//获取性别
				if (parseInt(UUserCard.substr(16, 1)) % 2 == 1) {
					//男
					return "1";
				} else {
					//女
					return "2";
				}
			}
			if (num == 3) {
				//获取年龄
				let myDate = new Date();
				let month = myDate.getMonth() + 1;
				let day = myDate.getDate();
				let age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
				if (
					UUserCard.substring(10, 12) < month ||
					(UUserCard.substring(10, 12) == month &&
						UUserCard.substring(12, 14) <= day)
				) {
					age++;
				}
				return age;
			}
			/* let birthday = "";
			if (idCard != null && idCard != "") {
				if (idCard.length == 15) {
					birthday = "19" + idCard.substr(6, 6);
				} else if (idCard.length == 18) {
					birthday = idCard.substr(6, 8);
				}

				birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
			}
			return birthday; */

		}


		
/**
 * 浮点运算（加，减，乘，除）
 */
 Vue.prototype.float_calculator = function float_calculator(fun, arg1, arg2) {
	return float_calculators[fun](arg1, arg2);
}

//对象格式写法
var float_calculators = {
	/**
	 * 1.记录两个运算数小数点后的位数
	 * 2.将其转化为整数类型进行运算
	 * 3.移动小数点的位置
	**/
	add: function (arg1, arg2) {
		let r1, r2, m;
		try {
			//取小数位长度
			r1 = arg1.toString().split(".")[1].length;
			r2 = arg2.toString().split(".")[1].length;
		} catch (e) {
			r1 = 0; r2 = 0;
		}
		m = Math.pow(10, Math.max(r1, r2));		//计算因子

		return (arg1 * m + arg2 * m) / m;
	},
	minus: function (arg1, arg2) {
		return this.add(arg1, -arg2);
	},
	mul: function (arg1, arg2) {
		let r1, r2, m;
		try {
			//取小数位长度
			r1 = arg1.toString().split(".")[1].length;
			r2 = arg2.toString().split(".")[1].length;
		} catch (e) {
			r1 = 0; r2 = 0;
		}
		m = Math.pow(10, Math.max(r1, r2));		//计算因子

		return (arg1 * m) * (arg2 * m) / (m * m);
	},
	div: function (arg1, arg2) {
		if (arg2 == 0) return 0;
		let t1 = 0, t2 = 0, r1, r2;
		try {
			t1 = arg1.toString().split(".")[1].length;
			t2 = arg2.toString().split(".")[1].length;
		} catch (e) { }

		if (Math) {
			r1 = Number(arg1.toString().replace(".", ""));
			r2 = Number(arg2.toString().replace(".", ""));
		}

		return (r1 / r2) * Math.pow(10, t2 - t1);
	}
};




	}
}
/*


// 添加日期范围
export function addDateRange(params, dateRange) {
	var search = params;
	search.beginTime = "";
	search.endTime = "";
	if (null != dateRange && '' != dateRange) {
		search.beginTime = dateRange[0];
		search.endTime = dateRange[1];
	}
	return search;
}

// 回显数据字典
export function selectDictLabel(datas, value) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (datas[key].dictValue == ('' + value)) {
			actions.push(datas[key].dictLabel);
			return true;
		}
	})
	return actions.join('');
}

// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
	var actions = [];
	var currentSeparator = undefined === separator ? "," : separator;
	var temp = value.split(currentSeparator);
	Object.keys(value.split(currentSeparator)).some((val) => {
		Object.keys(datas).some((key) => {
			if (datas[key].dictValue == ('' + temp[val])) {
				actions.push(datas[key].dictLabel + currentSeparator);
			}
		})
	})
	return actions.join('').substring(0, actions.join('').length - 1);
}

// 通用下载方法
export function download(fileName) {
	window.location.href = baseURL + "/common/download?fileName=" + encodeURI(fileName) + "&delete=" + true;
}

// 字符串格式化(%s )
export function sprintf(str) {
	var args = arguments, flag = true, i = 1;
	str = str.replace(/%s/g, function () {
		var arg = args[i++];
		if (typeof arg === 'undefined') {
			flag = false;
			return '';
		}
		return arg;
	});
	return flag ? str : '';
}

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
	if (!str || str == "undefined" || str == "null") {
		return "";
	}
	return str;
}
*/
/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */

/*
export function handleTree(data, id, parentId, children, rootId) {
	id = id || 'id'
	parentId = parentId || 'parentId'
	children = children || 'children'
	rootId = rootId || Math.min.apply(Math, data.map(item => { return item[parentId] })) || 0
	//对源数据深度克隆
	const cloneData = JSON.parse(JSON.stringify(data))
	//循环所有项
	const treeData = cloneData.filter(father => {
		let branchArr = cloneData.filter(child => {
			//返回每一项的子级数组
			return father[id] === child[parentId]
		});
		branchArr.length > 0 ? father.children = branchArr : '';
		//返回第一层
		return father[parentId] === rootId;
	});
	return treeData != '' ? treeData : data;
}




/**
 * 地址
 * 通过地区编码获取地区名称
 */
//  import { CodeToText } from "element-china-area-data";
//  export function address(item) {
// 		//通过地区编码获取地区名称
// 		let address = "";
// 		let county = item.county ? CodeToText[item.county] : "";
// 		if (item.province) {
// 			address =
// 				CodeToText[item.province] +
// 				CodeToText[item.city] +
// 				county +
// 				item.area;
// 		} else {
// 			address = item.area ? item.area : "";
// 		}
// 		return address;	}
